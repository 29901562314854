import styled from 'styled-components';
import { Input } from './Input';
import { Button } from './Button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const StyledForm = styled.form`
	font-family: 'InterUI';
	display: flex;
	flex-flow: column;
`;

export const Row = styled.div`
	display: flex;
	flex-flow: row;

	& > *:not(:first-child) {
		margin-left: 16px;
	}
`;

const ErrorMessage = styled.div`
	font-size: 0.7rem;
	margin-top: 0.5rem;
	color: var(--color-error);
`;

interface UpdatePhoneVerificationProps {
	phoneNumber: string;
	onSubmit: (code: string) => void;
	error?: string;
}

export const UpdatePhoneVerification: React.FC<UpdatePhoneVerificationProps> = ({ phoneNumber, onSubmit, error }) => {
	const [value, setValue] = useState<string>('');
	const { t } = useTranslation('account');

	return (
		<StyledForm>
			<p dangerouslySetInnerHTML={{ __html: t('addPhoneDialog.verificationCodeContent', { phoneNumber }) }} />
			<Row>
				<Input
					type={'text'}
					value={value}
					onChange={(e) => /^[0-9]{0,6}$/.test(e.target.value) && setValue(e.target.value)}
				/>
				<Button
					type={'submit'}
					onClick={(ev) => {
						ev.preventDefault();
						onSubmit(value);
					}}
				>
					{t('addPhoneDialog.verify')}
				</Button>
			</Row>
			{error && <ErrorMessage>{error}</ErrorMessage>}
		</StyledForm>
	);
};
