import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarItem } from '@/components/SidebarItem';
import { Category, LookupApi } from '@smartswap/client-api';
import styled from 'styled-components';
import { config } from '@/configuration';
import { screenSize } from '@/ScreenSize';
import categoryColors from './categoryColors.json';
import { useTranslation } from 'react-i18next';
import useAsyncEffect from 'use-async-effect';
import { DialogContext } from '@/contexts/DialogContext';

export const SidebarGroup = styled.div`
	padding: 8px 0;
	border-bottom: 1px solid rgba(215, 225, 234, 1);

	@media screen and ${screenSize.mobile} {
		border-bottom: none;
		padding: 0;
	}

	&:last-of-type {
		border-bottom: none;
	}
`;

interface CategoryIconBackgroundProps {
	$backgroundColor?: string;
	$size?: number;
}

const CategoryIconBackground = styled.div<CategoryIconBackgroundProps>`
	width: ${(p) => p.$size || 36}px;
	height: ${(p) => p.$size || 36}px;
	border-radius: ${(p) => (p.$backgroundColor ? '1000px' : 0)};
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	background-color: ${(p) => p.$backgroundColor || 'transparent'};

	.active & {
		background-color: ${(p) => p.$backgroundColor || 'transparent)'};
		box-shadow: ${(p) =>
			p.$backgroundColor ? '0 8px 16px rgba(67, 86, 100, 0.04), 0 4px 8px rgba(67, 86, 100, 0.08)' : 'none'};
	}

	img {
		max-width: ${(p) => p.$size || 36}px;
		max-height: ${(p) => p.$size || 36}px;
	}
`;

const CategoryIcon: React.FC<{
	id: string;
	size?: number;
}> = ({ id, size }) => {
	const backgroundColorRaw = (
		categoryColors as {
			[key: string]: string;
		}
	)[id];
	const backgroundWithAlpha = backgroundColorRaw !== 'none' ? `${backgroundColorRaw}16` : undefined;

	return (
		<CategoryIconBackground $size={size} $backgroundColor={backgroundWithAlpha}>
			<img src={`/icons/categories/${id}.svg`} alt={id} />
		</CategoryIconBackground>
	);
};

const CategoriesNav: React.FC<{
	categories: Category[];
}> = ({ categories }) => {
	const { t } = useTranslation('categories');
	const { closeAll } = useContext(DialogContext);

	return (
		<>
			{categories.map((category) => (
				<NavLink to={`/offers/category/${category.slug}`} key={category.slug} onClick={closeAll}>
					<SidebarItem>
						<CategoryIcon id={category.slug} size={42} />
						{t(category.slug)}
					</SidebarItem>
				</NavLink>
			))}
		</>
	);
};

export const CategoriesList: React.FC = () => {
	const [categories, setCategories] = useState<Category[]>([]);
	const { t } = useTranslation('categories');
	const { closeAll } = useContext(DialogContext);

	useAsyncEffect(async () => {
		const categoriesResponse = await new LookupApi(config).lookupCategoriesGet();
		setCategories(categoriesResponse.categories);
	}, []);

	return (
		<SidebarGroup>
			<NavLink to={'/'} onClick={closeAll}>
				<SidebarItem>
					<CategoryIcon id={'all'} />
					{t('allCategories')}
				</SidebarItem>
			</NavLink>
			<CategoriesNav categories={categories} />
		</SidebarGroup>
	);
};
