import * as React from 'react';
import styled from 'styled-components';
import { PropsWithChildren } from 'react';
import { LightDropdown } from '@/components/Dropdown';

export const GridHeader = styled.div`
	font-size: 20px;
	letter-spacing: -0.4px;
	margin-bottom: 40px;
	
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	gap: 16px;
	flex-wrap: wrap;
`;

export const GridGroup = styled.div``;
