import React, { useContext } from 'react';
import { Link, Route, Routes, useMatch } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '@/Icons/Basic/Logo.svg';
import { CreateOfferDialog } from './CreateOfferDialog';
import { ProfileMenu } from './ProfileMenu';
import styled from 'styled-components';
import { MenuIcon, PlusIcon } from '@/Icons';
import { screenSize } from '@/ScreenSize';
import { SearchBox } from './SearchBox';
import { Button } from './Button';
import { DialogContext } from '@/contexts/DialogContext';
import { MemberContext } from '@/contexts/MemberContext';
import { CategoryDialog } from './Categories/CategoryDialog';
import { CreateOfferCriteriaWizard } from './CreateOfferCriteriaWizard';
import { useTranslation } from 'react-i18next';
import { LocaleChanger } from './LocaleChanger';

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
`;

interface WrapperProps {
	$multiline?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
	width: 100%;
	border: 1px solid rgba(215, 225, 234, 1);
	background: rgba(255, 255, 255, 1);
	box-sizing: border-box;
	display: grid;
	padding: 0;
	grid-template:
		'logo language add-button profile-menu' 60px
		${(p) =>
			p.$multiline ? `'search-box search-box search-box search-box' 60px / auto 1fr auto` : ' / auto 1fr auto'};

	@media screen and ${screenSize.sm} {
		grid-template: 'logo search-box add-button language profile-menu' / 2fr 3fr 1fr auto;
	}
`;

const MenuButton = styled(Button).attrs({
	$color: 'ghost',
})`
	--current-button-icon-color: var(--menu-button-color);
	margin-right: 8px;

	@media screen and ${screenSize.sm} {
		display: none;
	}
`;

interface GroupProps {
	$groupName?: string;
	$justify?: string;
}

const Group = styled.div<GroupProps>`
	padding: 0 12px;
	box-sizing: border-box;
	${(p) => p.$groupName && `grid-area: ${p.$groupName}`};
	display: flex;
	flex-flow: row;
	width: 100%;
	align-items: center;
	${(p) => p.$justify && `justify-content: ${p.$justify}`};
`;

const StyledPlusIcon = styled(PlusIcon)`
	path {
		fill: white;
	}

	@media screen and ${screenSize.sm} {
		margin-right: 6px;
	}
`;

const AppBarSearchBox = styled(SearchBox)`
	width: 100%;
`;

const AddButton = styled(Button)`
	grid-area: add-button;
	display: flex;
	flex-flow: row;
	align-items: center;
	background: rgba(246, 95, 67, 1);
`;

const HideOnSm = styled.span`
	display: none;

	@media ${screenSize.sm} {
		display: initial;
	}
`;

interface HeaderProps {
	className?: string;
}

export const Header: React.FC<HeaderProps> = () => {
	const { showDialog } = useContext(DialogContext);
	const memberContext = useContext(MemberContext);
	const messageMatch = useMatch('/messages/*');
	const { t } = useTranslation('account');

	return (
		<Wrapper $multiline={!messageMatch}>
			<Group $groupName={'logo'}>
				<Link to={'/'}>
					<LogoWrapper>
						<LogoIcon style={{ paddingTop: '10px' }} />
						<HideOnSm>SmartSwap</HideOnSm>
					</LogoWrapper>
				</Link>
			</Group>

			<Routes>
				<Route path={'/messages/*'} element={null} />
				<Route
					path={'/*'}
					element={
						<Group $groupName={'search-box'}>
							<MenuButton onClick={() => showDialog(<CategoryDialog />)}>
								<MenuIcon />
							</MenuButton>
							<AppBarSearchBox />
						</Group>
					}
				></Route>
			</Routes>

			{memberContext.fullName && (
				<Group $groupName={'add-button'} $justify={'flex-end'}>
					<AddButton
						onClick={() => {
							memberContext?.flags?.canCreateOffer
								? showDialog(<CreateOfferDialog />)
								: showDialog(<CreateOfferCriteriaWizard />);
						}}
					>
						<StyledPlusIcon />
						<HideOnSm dangerouslySetInnerHTML={{ __html: t('addItem') }} />
					</AddButton>
				</Group>
			)}

			<Group $groupName={'language'}>
				<LocaleChanger />
			</Group>

			<Group $groupName={'profile-menu'} $justify={'flex-end'}>
				<ProfileMenu />
			</Group>
		</Wrapper>
	);
};
