import React from 'react';
import styled, { css } from 'styled-components';
import {
	AddLikeIcon,
	BasketFavouriteIcon,
	BottomWavesIcon,
	BoxIcon,
	CreditCardIcon,
	HorizontalLine2Icon,
	HorizontalLineIcon,
	LoudSpeakerIcon,
	SelfieIcon,
	StockIcon,
	TopBackgroundIcon,
} from '../Icons/marketing';
import { FeatureList } from '@/components/Homepage/FeatureList';
import { Collapsible } from '@/components/Collapsible';
import { screenSize } from '@/ScreenSize';
import { HomepageLogos } from '@/components/Homepage/HomepageLogos';
import { OffersCarousel } from '@/components/OffersCarousel';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
	margin: -16px;
	padding: 0 32px 32px;
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	flex-flow: column;
	box-sizing: border-box;
	overflow: hidden;
`;

const ContentBlock = styled.div`
	max-width: 700px;

	h2 {
		margin-top: 192px;
		font-family: Poppins;
		font-style: normal;
		font-weight: 600;
		font-size: 32px;
		text-align: center;
		letter-spacing: -0.4px;
		color: #35425b;

		@media screen and ${screenSize.mobile} {
			margin-top: 96px;
			margin-bottom: 16px;
		}
	}

	h3 {
		margin-top: 128px;
		margin-bottom: 64px;
		font-family: Poppins;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 1.33;
		text-align: center;
		letter-spacing: -0.4px;
		color: #35425b;

		@media screen and ${screenSize.mobile} {
			margin-top: 80px;
			margin-bottom: 32px;
		}
	}

	p {
		font-family: InterUI, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 2;
		text-align: center;
		color: #72809d;

		&:first-of-type {
			margin-top: 0;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		@media screen and ${screenSize.mobile} {
			line-height: 1.86;
		}
	}
`;

const MainHeading = styled.h1`
	letter-spacing: -0.4px;
	text-align: center;
	color: #ffffff;
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	text-shadow: 0 8px 16px rgba(67, 86, 100, 0.04), 0 4px 8px rgba(67, 86, 100, 0.08);
	margin-bottom: 32px;

	@media screen and ${screenSize.mobile} {
		margin-bottom: 24px;
	}
`;

const BackgroundBlockTop = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: -1;
	height: 840px;
	display: flex;
	justify-content: center;
`;

const BackgroundBlockBottom = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	height: 200px;

	@media screen and ${screenSize.mobile} {
		height: 150px;
	}
`;

const HeadingContent = styled.p`
	&& {
		font-family: InterUI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: #ffffff;
		text-align: center;
		line-height: 1.78;
		margin-bottom: 0;
	}
`;

const IconWrapper = styled.div<{ isGreen?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 96px;
	width: 96px;
	border-radius: 1000px;

	${(p) =>
		p.isGreen
			? css`
					background: linear-gradient(180deg, #aaeb31 0%, #71d316 100%);
					box-shadow: 0 0 2.94837px rgba(60, 64, 85, 0.115849);
			  `
			: css`
					background: linear-gradient(82.8deg, #696cff -8.3%, #b992ff 139.65%);
					box-shadow: -3px 7px 10px rgba(141, 125, 255, 0.221345), -1px 1px 1px rgba(73, 68, 170, 0.308375);
			  `}
	
	@media screen ${screenSize.mobile} {
		width: 64px;
		height: 64px;
		
		svg {
			width: 32px;
			height: 32px;
		}
	}
`;

const HeadingBlock = styled(ContentBlock)`
	margin-top: 100px;

	@media screen and ${screenSize.mobile} {
		margin-top: 72px;
	}
`;

const VideoWrapper = styled.div`
	width: 100%;
	max-width: 768px;
	margin-top: 94px;
	box-shadow: 0 16px 32px rgba(67, 86, 100, 0.12);
	border-radius: 8px;
	overflow: hidden;

	@media screen and ${screenSize.mobile} {
		margin-top: calc(74px);
	}
`;

const HorizontalFlow = styled.div`
	position: relative;
	width: 100%;
	max-width: 960px;
	display: flex;
	flex-flow: row;
	justify-content: space-between;

	@media screen and ${screenSize.mobile} {
		flex-flow: column;
	}
`;

export const VerticalFlow = styled.div`
	position: relative;
	width: 100%;
	max-width: 960px;
	display: flex;
	flex-flow: column;
`;

const FlowTitle = styled.h4`
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 1.5;
	text-align: center;
	letter-spacing: -0.4px;

	color: #35425b;

	@media screen and ${screenSize.mobile} {
		text-align: left;
		margin: 0;
	}
`;

const FlowPointContent = styled.div`
	font-family: InterUI;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 1.71;
	text-align: center;
	color: #72809d;

	@media screen and ${screenSize.mobile} {
		text-align: left;
	}
`;

// const HorizontalLineWrapper = styled.div`
// 	position: absolute;
// 	height: 100px;
// 	left: 10%;
// 	right: 10%;
// 	top: 10px;
// `;

const HorizontalLineWrapper = styled.div<{ isGreen?: boolean }>`
	position: absolute;
	height: 100px;
	left: 10%;
	right: 10%;
	top: 10px;

	@media screen and ${screenSize.mobile} {
		left: 60px;
		width: ${(p) => (p.isGreen ? '320px' : '470px')};

		svg {
			transform-origin: top left;
			transform: rotate(90deg);
		}
	}
`;

const FlowPoint = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	max-width: 200px;
	justify-content: space-between;

	@media screen and ${screenSize.mobile} {
		justify-content: flex-start;
		flex-flow: row;
		max-width: none;

		&:not(:last-child) {
			margin-bottom: 52px;
		}
	}
`;

const FlowPointContentWrapper = styled.div`
	display: flex;
	flex-flow: column;

	@media screen and ${screenSize.mobile} {
		margin-left: 20px;
	}
`;

const CarouselBlock = styled.div`
	max-width: 960px;
	width: 100%;
	display: flex;
	margin-top: calc(176px - 1em);
	flex-flow: column;

	@media screen and ${screenSize.mobile} {
		margin-top: calc(96px - 1em);
	}
`;

const CarouselFooter = styled.div`
	margin-top: 24px;
	text-align: center;
	display: none;

	a {
		font-family: InterUI;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 1.5;

		text-align: right;
		text-decoration-line: underline;
		color: var(--color-brand);
	}

	@media screen and ${screenSize.mobile} {
		display: block;
	}
`;

const CarouselHeader = styled.div`
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;

	a {
		font-family: InterUI;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 1.5;

		text-align: right;
		text-decoration-line: underline;
		color: var(--color-brand);
	}

	@media screen and ${screenSize.mobile} {
		text-align: center;
		font-size: 24px;
		line-height: 1.33;
		text-align: center;
		letter-spacing: -0.4px;

		a {
			display: none;
		}
	}
`;

export const HomePage = () => {
	const { t } = useTranslation('homepage');
	const { t: tFaq } = useTranslation('faq'); // Not sure why ns override does not work

	return (
		<Wrapper>
			<BackgroundBlockTop>
				<TopBackgroundIcon />
			</BackgroundBlockTop>

			<HeadingBlock>
				<MainHeading>{t('mainHeading')}</MainHeading>
				<HeadingContent>{t('headingContent')}</HeadingContent>
			</HeadingBlock>

			<VideoWrapper>
				<div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
					<iframe
						title={'SmartSwap promotional video'}
						src="https://player.vimeo.com/video/385808535?color=696CFF&title=0&byline=0&portrait=0"
						style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none' }}
						allow="autoplay; fullscreen"
						allowFullScreen={true}
					/>
				</div>
				<script src="https://player.vimeo.com/api/player.js"></script>
			</VideoWrapper>

			<CarouselBlock>
				<CarouselHeader>
					<h2>{t('lookingForOwner')}</h2>
					<Link to={'/offers'}>{t('seeAllItems')}</Link>
				</CarouselHeader>
				<OffersCarousel />
				<CarouselFooter>
					<Link to={'/offers'}>{t('seeAllItems')}</Link>
				</CarouselFooter>
			</CarouselBlock>

			<ContentBlock>
				<h2>{t('howDoesItWork')}</h2>
				<p>{t('howDoesItWorkContent')}</p>
			</ContentBlock>

			<ContentBlock>
				<h3>{t('givingItemsAway')}</h3>
			</ContentBlock>

			<HorizontalFlow>
				<HorizontalLineWrapper>
					<HorizontalLineIcon />
				</HorizontalLineWrapper>
				{[
					{
						icon: <SelfieIcon />,
						title: t('listItem'),
						content: t('listItemContent'),
					},
					{
						icon: <LoudSpeakerIcon />,
						title: t('receiveNotification'),
						content: t('receiveNotificationContent'),
					},
					{
						icon: <BoxIcon />,
						title: t('sendItem'),
						content: t('sendItemContent'),
					},
					{
						icon: <AddLikeIcon />,
						title: t('receivePoints'),
						content: t('receivePointsContent'),
					},
				].map((point) => (
					<FlowPoint key={point.title}>
						<div>
							<IconWrapper>{point.icon}</IconWrapper>
						</div>
						<FlowPointContentWrapper>
							<FlowTitle>{point.title}</FlowTitle>
							<FlowPointContent>{point.content}</FlowPointContent>
						</FlowPointContentWrapper>
					</FlowPoint>
				))}
			</HorizontalFlow>

			<ContentBlock>
				<h3>{t('orderingNewItems')}</h3>
			</ContentBlock>
			<HorizontalFlow style={{ maxWidth: '705px' }}>
				<HorizontalLineWrapper isGreen={true}>
					<HorizontalLine2Icon />
				</HorizontalLineWrapper>
				{[
					{
						icon: <BasketFavouriteIcon />,
						title: t('chooseItem'),
						content: t('chooseItemContent'),
					},
					{
						icon: <CreditCardIcon />,
						title: t('payForDelivery'),
						content: t('payForDeliveryContent'),
					},
					{
						icon: <StockIcon />,
						title: t('itemDelivered'),
						content: t('itemDeliveredContent'),
					},
				].map((point) => (
					<FlowPoint key={point.title}>
						<div>
							<IconWrapper isGreen={true}>{point.icon}</IconWrapper>
						</div>
						<FlowPointContentWrapper>
							<FlowTitle>{point.title}</FlowTitle>
							<FlowPointContent>{point.content}</FlowPointContent>
						</FlowPointContentWrapper>
					</FlowPoint>
				))}
			</HorizontalFlow>

			<ContentBlock>
				<h2>
					{t('why')} <span style={{ color: 'var(--color-brand)' }}>SmartSwap</span>?
				</h2>
			</ContentBlock>
			<VerticalFlow>
				<FeatureList />
			</VerticalFlow>

			<ContentBlock>
				<h2>{tFaq('title')}</h2>
			</ContentBlock>
			<VerticalFlow style={{ maxWidth: '762px', marginTop: '32px' }}>
				{(tFaq('items', { returnObjects: true }) as { title: string; content: string }[])
					?.slice(0, 6)
					.map((faq) => (
						<Collapsible key={faq.title} title={faq.title}>
							{faq.content}
						</Collapsible>
					))}
			</VerticalFlow>

			<HomepageLogos />

			<BackgroundBlockBottom>
				<BottomWavesIcon />
			</BackgroundBlockBottom>
		</Wrapper>
	);
};
