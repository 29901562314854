import { ChatApi, ListChatsMessagesResponse } from '@smartswap/client-api';
import { config } from '@/configuration';

const chatApi = new ChatApi(config);

export const getChats = () => {
	return chatApi.myChatsGet();
};

export const getMessages = (id: string): Promise<ListChatsMessagesResponse> => {
	return chatApi.myChatsIdMessagesGet({ id });
};

export const beginChat = (id: string) => {
	return sendMessage(id, ' ');
};

export const sendMessage = (id: string, text: string, offerId?: string) => {
	return chatApi.myChatsIdMessagesPost({ id: id, sendDirectMessageRequest: { text, offerId } });
};

export const getChat = (id: string) => {
	return chatApi.myChatsIdMessagesGet({ id: id });
};
