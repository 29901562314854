import React, { PropsWithChildren, useState } from 'react';
import styled, { css } from 'styled-components';
import { DownIcon, RightIcon } from '@/Icons';

interface VisibilityToggle {
	isOpen?: boolean;
}

const Wrapper = styled.div<VisibilityToggle>`
	cursor: pointer;
	padding: 20px 24px 20px 52px;
	box-sizing: border-box;
	display: flex;
	flex-flow: column;
	border-radius: 6px;
	user-select: none;
	width: 100%;

	${(p) =>
		p.isOpen &&
		css`
			background-color: #ffffff;
			box-shadow: 0 16px 32px rgba(67, 86, 100, 0.12);
			margin-bottom: 8px;
		`}

	&:hover {
		background-color: #ffffff;
		box-shadow: 0 2px 5px rgba(67, 86, 100, 0.08), 0 1px 2px rgba(67, 70, 74, 0.04);
	}
`;

const TitleWrapper = styled.div`
	position: relative;
	font-weight: 500;
	font-style: normal;
	font-family: InterUI;
	display: flex;
	align-items: baseline;
	line-height: 1.5;
	font-size: 16px;
	color: #35425b;

	svg {
		top: 4px;
		position: absolute;
		left: -28px;

		path {
			fill: var(--color-brand);
		}
	}
`;

const Content = styled.div<VisibilityToggle>`
	font-family: InterUI;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 1.5;
	color: #72809d;
	display: ${(p) => (p.isOpen ? 'block' : 'none')};
	margin-top: 16px;
`;

interface CollapsibleProps {
	title: string;
}

export const Collapsible: React.FC<PropsWithChildren<CollapsibleProps>> = ({ children, title }) => {
	const [isOpen, setIsOpen] = useState(false);

	function toggleOpen() {
		setIsOpen(!isOpen);
	}

	return (
		<Wrapper isOpen={isOpen} onClick={toggleOpen}>
			<TitleWrapper>
				{isOpen ? <DownIcon /> : <RightIcon />}
				<div>{title}</div>
			</TitleWrapper>
			<Content isOpen={isOpen}>{children}</Content>
		</Wrapper>
	);
};
