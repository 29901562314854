import styled from 'styled-components';

export const Row = styled.div`
	display: flex;
	flex-flow: row;
	& > * {
		flex: 1;

		&:not(:first-child) {
			margin-left: 16px;
		}
	}
`;
