import { LookupApi, MarketApi } from '@smartswap/client-api';
import { config } from '@/configuration';

const lookupApi = new LookupApi(config);
const marketApi = new MarketApi(config);

export function getCategories() {
	return lookupApi.lookupCategoriesGet();
}

export function searchOffers() {
	return marketApi.searchGet({});
}

export function reserveOffer(id: string, barcode: string) {
	return marketApi.offersIdReservationPost({
		id,
		createReservationRequest: {
			barcode,
		},
	});
}
