import styled from 'styled-components';
import { Dropdown } from '@/components/Dropdown/Dropdown';
import React, { PropsWithChildren } from 'react';
import { ChevronDown } from 'react-feather';

export const LightDropdown = styled(Dropdown)`
	font-size: 14px;

	label {
		color: var(--color-gray-500);
		margin-right: 8px;
	}
	
	display: flex;
	flex-flow: row;
	align-items: flex-end;
`;

const Chevron = styled(ChevronDown)`
	margin-left: 8px;
	width: 14px;
`;

const Wrapper = styled.div`
	display: flex;
	flex-flow: row;
	align-items: flex-end;
`

export const DropdownSelector = ({children}: PropsWithChildren) => {

	return <Wrapper><span>{children}</span><Chevron/></Wrapper>
}
