import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { Offer } from '@smartswap/client-api';
import { getOffer } from '@/services/Offer';
import { MessageAuthor, MessageBubble } from './MessageBubble';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface OfferMessageBubbleProps extends MessageAuthor {
	style?: CSSProperties;
	className?: string;
	offerId?: string;
}

const Thumbnail = styled.img`
	height: 60px;
	border-radius: var(--border-radius);
`;

const Bubble = styled(MessageBubble)`
	display: flex;
	flex-flow: row;
	align-items: center;
	padding: 0;
	min-height: 60px;
  	min-width: 80px;
`;

const Title = styled.div`
	font-family: Poppins, sans-serif;
	font-weight: bold;
	margin: 16px 13px;
`;

export const OfferMessageBubble: FC<OfferMessageBubbleProps> = ({ offerId, ...messageBubbleProps }) => {
	const [offer, setOffer] = useState<Offer>();

	useEffect(() => {
		if (!offerId) return;
		getOffer(offerId).then(setOffer); //TODO: optimize and remove additional requests
	}, [offerId]);

	// if (!offer) return <div style={{minHeight: '76px'}}></div>; // TODO: Fix temp placeholder

	const imgSrc = offer?.media?.[0]?.src;

	return (
		<Bubble {...messageBubbleProps}>
			{imgSrc && <Thumbnail src={imgSrc} alt={offer?.details.title} />}
			<Link to={`/item/${offer?.id}`}>
				<Title>{offer?.details.title}</Title>
			</Link>
		</Bubble>
	);
};
