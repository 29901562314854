import React, { useEffect, useState } from 'react';
import { GridView } from '@/components/GridView';
import { GridGroup, GridHeader } from '@/components/GridGroup';
import { OffersGrid } from '@/components/OffersGrid';
import { Button } from '@/components/Button';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroller';
import { useFeed } from '@/hooks/useFeed';
import { useTranslation } from 'react-i18next';
import { SearchGetStateEnum } from '@smartswap/client-api';

const LoadMoreButton = styled(Button)`
	margin-top: 32px;
	width: 100%;
`;

interface FeedPageProps {
	defaultCategory?: string;
}

export const StatsPage: React.FC<FeedPageProps> = ({ defaultCategory }) => {
	const { offers, loadMore, setState, hasMore } = useFeed();
	const [autoLoad, setAutoLoad] = useState<boolean>(false);
	const { t } = useTranslation(['common', 'categories', 'minimalism']);

	useEffect(() => {
		setAutoLoad(false);
		setState(SearchGetStateEnum.Traded);
	}, []);

	function startAutoload() {
		setAutoLoad(true);
	}

	const header = t(`common:swapped_items`);

	return (
		<GridView>
			<GridGroup>
				<GridHeader>{header}</GridHeader>

				<InfiniteScroll loadMore={loadMore} hasMore={autoLoad && hasMore}>
					<OffersGrid offers={offers} viewOnly={true}>
					</OffersGrid>
				</InfiniteScroll>
				{hasMore && !autoLoad && (
					<LoadMoreButton onClick={startAutoload}>{t('loadMoreItems', { ns: 'common' })}</LoadMoreButton>
				)}
			</GridGroup>
		</GridView>
	);
};
