import React, { CSSProperties, PropsWithChildren, useState } from 'react';
import { Button } from './Button';
import styled from 'styled-components';
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	TelegramIcon,
	TelegramShareButton,
	TwitterIcon,
	TwitterShareButton,
} from 'react-share';

interface ShareButtonsProps {
	className?: string;
	style?: CSSProperties;
}

const ButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-flow: row;
	flex-wrap: nowrap;
	align-items: center;
	font-size: 0;

	button:not(:last-child) {
		margin-right: 8px;
	}
`;

const ShareButton = styled(Button).attrs({
	$width: 'full',
	$variant: 'outline',
})`
	font-size: 16px;
	padding-top: 12px;
	padding-bottom: 12px;
`;

export const ShareButtons: React.FC<PropsWithChildren<ShareButtonsProps>> = ({ className, style, children }) => {
	const [isShowingButtons, setShowButtons] = useState<boolean>(false);
	const shareUrl = window.location.href;
	const iconStyle = { size: 46, borderRadius: 5 };

	function showButtons() {
		setShowButtons(true);
	}

	function hideButtons() {
		setShowButtons(false);
	}

	return (
		<div onClick={showButtons} onMouseLeave={hideButtons} onFocus={showButtons} onBlur={hideButtons}>
			{!isShowingButtons ? (
				<ShareButton className={className} style={style}>
					{children}
				</ShareButton>
			) : (
				<ButtonsContainer>
					<FacebookShareButton url={shareUrl}>
						<FacebookIcon {...iconStyle} />
					</FacebookShareButton>
					<TwitterShareButton url={shareUrl}>
						<TwitterIcon {...iconStyle} />
					</TwitterShareButton>
					<TelegramShareButton url={shareUrl}>
						<TelegramIcon {...iconStyle} />
					</TelegramShareButton>
					<EmailShareButton url={shareUrl}>
						<EmailIcon {...iconStyle} />
					</EmailShareButton>
				</ButtonsContainer>
			)}
		</div>
	);
};
