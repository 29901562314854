import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	//background-color: #ff3e1d;
	color: var(--color-default);
	font-size: 12px;
	border-radius: 1000px;
	line-height: 1;
	padding: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-left: 16px;

	span {
		margin: 0 4px;
		z-index: 1;
	}

	svg {
		position: absolute;
	}
`;

export const ReminderCounter: React.FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
	return (
		<Wrapper className={className}>
			<span>{children}</span>
			<svg width={'100%'} height={'100%'} viewBox={'0 0 20 20'}>
				<circle cx={10} cy={10} r={10} fill={'#FF3E1D'} />
			</svg>
		</Wrapper>
	);
};
