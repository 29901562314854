import { Reminder, RemindersApi } from '@smartswap/client-api';
import { config, getUserFromStorage } from '@/configuration';

const remindersApi = new RemindersApi(config);

export async function getAllReminders() {
	let reminders: Reminder[];

	const user = getUserFromStorage();
	if (user) {
		reminders = await remindersApi.myRemindersGet().then((r) => r.reminders);
	} else {
		reminders = [];
	}

	return reminders;
}

export async function ackReminders(ids: string[]) {
	if (ids.length === 0) return;

	return await remindersApi.myRemindersAckPut({ ackRemindersRequest: { ids } });
}
