import { Form, Formik } from 'formik';
import { Button } from '@/components/Button';
import { InputField } from '@/components/Input';
import * as React from 'react';
import { CardHeader, FormGrid, InnerCardContent, Section, Title } from '@/pages/ProfileComponents';
import { useTranslation } from 'react-i18next';
import { useCallback, useRef } from 'react';
import { AuthApi } from '@smartswap/client-api';
import { config } from '@/configuration';
import { usePasswordValidationSchema } from '@/pages/ForgotPasswordPage';
import { useDialog } from '@/contexts/DialogContext';
import { Dialog } from '@/components/Dialog';

const authApi = new AuthApi(config);

async function updatePassword(oldPassword: string, newPassword: string) {
	return await authApi.authUpdatePasswordPut({
		updatePasswordRequest: {
			oldPassword,
			newPassword,
		},
	});
}

function PasswordChangeSuccessDialog() {
	const { t } = useTranslation('account');
	return (
		<Dialog title={t('resetPassword.title')}>
			<span dangerouslySetInnerHTML={{ __html: t('resetPassword.changePasswordSuccess') }} />
		</Dialog>
	);
}

function PasswordChangeFailDialog() {
	const { t } = useTranslation('account');
	return (
		<Dialog title={t('resetPassword.title')}>
			<span dangerouslySetInnerHTML={{ __html: t('resetPassword.changePasswordFailed') }} />
		</Dialog>
	);
}

export function ChangePasswordSection() {
	const { t } = useTranslation('account');
	const { showDialog } = useDialog();
	const passwordValidationSchema = usePasswordValidationSchema();
	const formRef = useRef<HTMLFormElement>(null);

	const onSubmitUpdatePassword = useCallback(async (formData: any) => {
		// console.log(formData);
		updatePassword(formData.oldPassword, formData.password)
			.then(() => {
				formRef.current?.reset();
				showDialog(<PasswordChangeSuccessDialog />);
			})
			.catch(() => {
				showDialog(<PasswordChangeFailDialog />);
			});
	}, []);

	return (
		<Section>
			<Formik
				validationSchema={passwordValidationSchema}
				initialValues={{ oldPassword: '', password: '', repeatPassword: '' }}
				onSubmit={onSubmitUpdatePassword}
			>
				<Form ref={formRef}>
					<InnerCardContent>
						<CardHeader>
							<Title>{t('resetPassword.title')}</Title>
							<Button type={'submit'}>{t('resetPassword.update')}</Button>
						</CardHeader>
						<FormGrid>
							<InputField label={t('resetPassword.oldPassword')} name={'oldPassword'} type={'password'} />
							<InputField label={t('resetPassword.newPassword')} name={'password'} type={'password'} />
							<InputField
								label={t('resetPassword.confirmPassword')}
								name={'repeatPassword'}
								type={'password'}
							/>
						</FormGrid>
					</InnerCardContent>
				</Form>
			</Formik>
		</Section>
	);
}
