import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Country, LookupApi, Offer, QuoteTypeEnum } from '@smartswap/client-api';
import { config } from '@/configuration';
import useAsyncEffect from 'use-async-effect';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@/lib/currency';

const RadioButton = styled.input.attrs({ type: 'radio' })`
	appearance: none;
	display: inline-block;
	position: relative;
	background-color: #f0f4f8;
	height: 16px;
	min-height: 16px;
	width: 16px;
	min-width: 16px;
	border: 1px solid #d7e1ea;
	border-radius: 100px;
	cursor: pointer;
	margin-right: 1em;
	padding: 1px;
	//outline: none;

	&:checked::before {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		content: '';
		width: 6px;
		height: 6px;
		background: var(--color-default);
		border-radius: 100px;
	}

	&:checked {
		border: none;
		background-color: var(--color-brand);
	}
`;

const Label = styled.label`
	display: flex;

	&:not(:last-of-type) {
		margin-bottom: 16px;
	}
`;

const InputText = styled.div`
	display: inline-block;

	.price {
		color: var(--checkout-price-color);
		white-space: nowrap;
	}

	& > div {
		font-family: InterUI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 1.54;
		color: #72809d;

		&:first-of-type {
			font-weight: 500;
			font-size: 14px;
			line-height: 1.57;
			color: #35425b;
		}
	}
`;

const Wrapper = styled.div`
	color: #35425b;
	font-size: 14px;
	font-weight: normal;
`;

const OptionsWrapper = styled.div`
	margin-top: 16px;
`;

interface DeliveryOptionsProps {
	onSelectionChange?: (barcode: string) => void;
	offer: Offer;
}

const countryByCode = (countries: Array<Country>, code: string) => {
	const country = countries.find((country) => country.code === code);
	return country ? country.name : '';
};

export const DeliveryOptions: FC<DeliveryOptionsProps> = ({ onSelectionChange, offer }) => {
	const [countries, setCountries] = useState<Country[]>([]);
	const lookupApi = new LookupApi(config);
	const { t } = useTranslation('checkout');

	const quotes = useMemo(
		() => offer?.quotes?.sort((a) => (a.type === QuoteTypeEnum.Inperson ? 1 : -1)) || [],
		[offer],
	);

	useEffect(() => {
		onSelectionChange?.(quotes[0].barcode);
	}, [onSelectionChange, quotes]);

	useAsyncEffect(async () => {
		const result = await lookupApi.lookupCountriesGet();
		setCountries(result?.countries || []);
	}, []);

	function onChange(event: React.ChangeEvent<HTMLInputElement>) {
		onSelectionChange?.(event.target.value);
	}

	return (
		<Wrapper>
			{t('chooseDelivery')}
			<OptionsWrapper>
				{quotes
					.filter((q) => q.type !== QuoteTypeEnum.Pickup) // TODO: Remove for pickup
					.map((quote, index) => {
						const text = t(`deliveryOptions.${quote.type}`, {
							courier: quote.courierSlug,
							shipping: formatCurrency(quote.shipping ?? 0),
						});

						let location = countryByCode(countries, offer?.owner?.country || '');
						if (offer?.owner?.location) {
							location = `${offer.owner.location}, ${location}`;
						}

						const description = t(`deliveryDescriptions.${quote.type}`, { location });

						return (
							<Label key={quote.barcode}>
								<RadioButton
									type={'radio'}
									name="option"
									value={quote.barcode}
									onChange={onChange}
									defaultChecked={index === 0}
								/>
								<InputText>
									<div dangerouslySetInnerHTML={{ __html: text }} />
									{description !== '' && <div>{description}</div>}
								</InputText>
							</Label>
						);
					})}
			</OptionsWrapper>
		</Wrapper>
	);
};
