import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Card, CardContent, CardHeader } from '@/components/Card/Card';
import { Button } from '@/components/Button';
import { InputField } from '@/components/Input';
import { AuthApi } from '@smartswap/client-api';
import { config } from '@/configuration';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

const authApi = new AuthApi(config);

const Wrapper = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

const LoginCard = styled(Card)`
	width: 340px;
`;

const LoginCardContent = styled(CardContent)`
	font-family: 'InterUI';
	font-weight: 500;
	font-size: 12px;
	padding-bottom: 20px;
`;

function useEmailValidationSchema() {
	const { t } = useTranslation('account');

	return Yup.object().shape({
		email: Yup.string()
			.email(t('resetPassword.validation.invalidEmail'))
			.required(t('resetPassword.validation.emailRequired')),
	});
}

export function usePasswordValidationSchema() {
	const { t } = useTranslation('account');

	return Yup.object().shape({
		password: Yup.string()
			.min(8, t('resetPassword.validation.minChars', { minCount: 8 }))
			.matches(/[A-Z]/, t('resetPassword.validation.upperCaseRequired'))
			.matches(/[a-z]/, t('resetPassword.validation.lowerCaseRequired'))
			.matches(/[\d]/, t('resetPassword.validation.digitRequired'))
			.required(t('resetPassword.validation.passwordRequired')),
		repeatPassword: Yup.string()
			.required(t('resetPassword.validation.passwordConfirmationRequired'))
			.oneOf([Yup.ref('password')], t('resetPassword.validation.passwordMatchRequired')),
	});
}

const StartState: React.FC<{ onSubmit: (email: string) => void }> = ({ onSubmit }) => {
	const { t } = useTranslation('account');
	const emailValidationSchema = useEmailValidationSchema();

	return (
		<Formik
			validationSchema={emailValidationSchema}
			initialValues={{ email: '' }}
			onSubmit={(values) => onSubmit(values.email)}
		>
			{(props) => (
				<form onSubmit={props.handleSubmit}>
					<InputField type={'email'} name={'email'} label={t('resetPassword.email')} />
					<Button $width={'full'} type={'submit'}>
						{t('resetPassword.continue')}
					</Button>
				</form>
			)}
		</Formik>
	);
};

const ClickState: React.FC<{ onFinish: () => void }> = (props) => {
	const { t } = useTranslation('account');

	return (
		<React.Fragment>
			<p>{t('resetPassword.linkSent')}</p>
			<Button $width={'full'} type={'submit'} onClick={() => props.onFinish()}>
				{t('resetPassword.finish')}
			</Button>
		</React.Fragment>
	);
};

type NewPasswordStateProps = {
	code: string;
	onSubmit: (code: string, password: string) => void;
};

const NewPasswordState: React.FC<NewPasswordStateProps> = ({ code, onSubmit }) => {
	const { t } = useTranslation('account');
	const passwordValidationSchema = usePasswordValidationSchema();

	return (
		<Formik
			validationSchema={passwordValidationSchema}
			initialValues={{ password: '', repeatPassword: '' }}
			onSubmit={(values) => onSubmit(code, values.password)}
		>
			{(props) => (
				<form onSubmit={props.handleSubmit}>
					<InputField type={'password'} name={'password'} label={t('resetPassword.newPassword')} />
					<InputField type={'password'} name={'repeatPassword'} label={t('resetPassword.confirmPassword')} />

					<Button $width={'full'} type={'submit'}>
						{t('resetPassword.continue')}
					</Button>
				</form>
			)}
		</Formik>
	);
};

const FinishState: React.FC<{ onFinish: () => void }> = (props) => {
	const { t } = useTranslation('account');

	return (
		<React.Fragment>
			<p>{t('resetPassword.recoveryComplete')}</p>
			<Button $width={'full'} type={'submit'} onClick={() => props.onFinish()}>
				{t('resetPassword.finish')}
			</Button>
		</React.Fragment>
	);
};

const CodeExpiredState: React.FC<{ onFinish: () => void }> = ({ onFinish }) => {
	const { t } = useTranslation('account');

	return (
		<React.Fragment>
			<p dangerouslySetInnerHTML={{ __html: t('resetPassword.codeExpired', { postProcess: 'md' }) }} />
			<Button $width={'full'} type={'submit'} onClick={() => onFinish()}>
				{t('resetPassword.finish')}
			</Button>
		</React.Fragment>
	);
};

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export const ForgotPasswordPage: React.FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('account');

	enum State {
		Start,
		Click,
		NewPassword,
		Finish,
		CodeExpired,
	}

	const [formState, setFormState] = useState<State>(State.Start);
	const query = useQuery();

	if (query.get('code') && formState === State.Start) {
		setFormState(State.NewPassword);
	}

	async function onStartRecovery(email: string) {
		return authApi
			.authPasswordResetPost({
				passwordResetRequest: {
					username: email,
				},
			})
			.finally(() => {
				// Must show success message even when no account exists.
				setFormState(State.Click);
			});
	}

	async function onCompleteRecovery(code: string, password: string) {
		return authApi
			.authPasswordResetConfirmPost({
				confirmPasswordResetRequest: {
					code: code,
					newPassword: password,
				},
			})
			.then(() => {
				setFormState(State.Finish);
			})
			.catch((reason) => {
				if (reason.status === 400) {
					setFormState(State.CodeExpired);
				}
			});
	}

	return (
		<Wrapper>
			<LoginCard>
				<CardHeader>{t('resetPassword.title')}</CardHeader>
				<LoginCardContent>
					{formState === State.Start && <StartState onSubmit={onStartRecovery} />}
					{formState === State.Click && <ClickState onFinish={() => navigate('/', { replace: true })} />}
					{formState === State.NewPassword && (
						<NewPasswordState code={query.get('code') || ''} onSubmit={onCompleteRecovery} />
					)}
					{formState === State.Finish && <FinishState onFinish={() => navigate('/login', { replace: true })} />}
					{formState === State.CodeExpired && (
						<CodeExpiredState
							onFinish={() => {
								navigate('/forgot', { replace: true });
								setFormState(State.Start);
							}}
						/>
					)}
				</LoginCardContent>
			</LoginCard>
		</Wrapper>
	);
};
