import { Offer } from '@smartswap/client-api';
import { getUserId } from '@/services/UserService';
import { useCallback, useMemo, useState } from 'react';
import { marketApi } from '@/services/api';

export function useOfferUpvote(offer: Offer) {
	const userId = getUserId();
	const [upvoteValue, setUpvoteValue] = useState<number>(offer.details.upvote || 0);
	const [hasUpVoted, setHasUpVoted] = useState<boolean>(!!offer.upvotedByUser);

	const canUpvote = useMemo(() => {
		return userId && offer.owner?.id !== userId;
	}, [offer, userId]);

	const toggleVote = useCallback(async () => {
		if (hasUpVoted) {
			setHasUpVoted(false);
			setUpvoteValue((prev) => prev - 1);
			await marketApi.offersIdDownvotePut({ id: offer.id });
		} else {
			setHasUpVoted(true);
			setUpvoteValue((prev) => prev + 1);
			await marketApi.offersIdUpvotePut({ id: offer.id });
		}
	}, [hasUpVoted, offer]);

	return { toggleVote, canUpvote, upvoteValue, hasUpVoted };
}
