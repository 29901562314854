import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AddIcon } from '@/Icons';
import { ImageTile, ImageTiles } from '../ImageTiles';
import { useTranslation } from 'react-i18next';
import { uploadImage } from '@/services/MediaService';

const Wrapper = styled.div`
	display: flex;
	flex-flow: row;

	> :not(:first-child) {
		margin-left: 16px;
	}
`;

const AddImageTile = styled(ImageTile)`
	border: 1px solid #dce1e7;
	font-family: 'InterUI';
	display: flex;
	flex-flow: column;
	justify-content: space-evenly;
	align-items: center;
	padding: 18px 0;
`;

export interface ImageProps {
	src: string;
	uuid: string;
}

interface ImageUploadProps {
	maxImages?: number;
	onChange?: (uuids: string[]) => void;
	initialImages?: ImageProps[];
	onUploadCountChange?: (count: number) => void;
}

export const ImageUpload = ({ maxImages = 3, initialImages, onChange, onUploadCountChange }: ImageUploadProps) => {
	const [files, setFiles] = useState<ImageProps[]>(initialImages || []);
	const imageInputRef = useRef<HTMLInputElement>(null);
	const { t } = useTranslation();
	const [uploadCount, setUploadCount] = useState(0);

	useEffect(() => {
		onUploadCountChange?.(uploadCount);
	}, [uploadCount, onUploadCountChange]);

	useEffect(() => {
		onChange?.(files.map((f) => f.uuid));
	}, [files, onChange]);

	const onFileChange = useCallback(
		(ev: ChangeEvent<HTMLInputElement>) => {
			const targetFiles = Array.from(ev.target.files || []);
			const fileCount = files.length;

			if (fileCount + targetFiles.length > maxImages) {
				targetFiles.splice(maxImages - fileCount);
			}

			for (const file of targetFiles) {
				setUploadCount((prev) => prev + 1);
				uploadImage(file)
					.then((r) => r.json())
					.then(({ uuid }) => {
						const objUrl = URL.createObjectURL(file);
						const newImage: ImageProps = { src: objUrl, uuid };
						setFiles((prevState) => [...prevState, newImage]);
					})
					.finally(() => {
						setUploadCount((prev) => prev - 1);
					});
			}
			ev.target.value = '';
		},
		[files, maxImages],
	);

	function triggerFilePicker() {
		if (!imageInputRef.current) return;
		imageInputRef.current.click();
	}

	const removeImage = useCallback(
		(removeFile: ImageProps) => {
			const index = files.indexOf(removeFile);
			const newFiles = [...files];
			newFiles.splice(index, 1);
			setFiles(newFiles);
		},
		[files, setFiles],
	);

	return (
		<Wrapper>
			<ImageTiles images={files} onRemove={removeImage} />

			{files.length < maxImages && (
				<>
					<AddImageTile onClick={triggerFilePicker}>
						<AddIcon />
						{t('photo', { count: maxImages - files.length })}
					</AddImageTile>
					<input
						style={{ position: 'absolute', opacity: 0, width: '1px', height: '1px', zIndex: -9000 }}
						ref={imageInputRef}
						name={'imageFile'}
						type={'file'}
						accept={'image/*;capture=camera'}
						onChange={onFileChange}
						multiple={true}
					/>
				</>
			)}
		</Wrapper>
	);
};
