import { Offer, Quote } from '@smartswap/client-api';
import React, { FC, useContext } from 'react';
import { Button } from '@/components/Button';
import { DialogContext } from '@/contexts/DialogContext';
import { ConfirmSwapDialog } from './ConfirmSwapDialog';
import { MemberContext } from '@/contexts/MemberContext';
import { SwapCriteriaWizard } from './SwapCriteriaWizard';
import { PointSaleWizard } from '@/components/PointSale/PointSaleWizard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface SwapButtonProps {
	offer: Offer;
	userId?: string | null;
	onUpdate?: () => void;
}

function findLowestCreditQuote(quotes: Quote[]) {
	if (!quotes?.length) return null;

	return quotes.reduce((previousValue, currentValue) => {
		return currentValue.credits < previousValue.credits ? currentValue : previousValue;
	});
}

export const SwapButton: FC<SwapButtonProps> = ({ offer, userId, onUpdate }) => {
	const member = useContext(MemberContext);
	const { showDialog } = useContext(DialogContext);
	const navigate = useNavigate();
	const { t } = useTranslation('item-page');

	function navToRegisterPage() {
		navigate('/register');
	}

	function displayConfirmationWindow() {
		showDialog(<ConfirmSwapDialog offer={offer} />);
	}

	function handleSuccessfulPointPurchase() {
		member.update();
		onUpdate?.();
	}

	function openPointSaleDialog() {
		showDialog(<PointSaleWizard onSuccess={handleSuccessfulPointPurchase} />);
	}

	function openSwapDialog() {
		if (member && !member?.flags?.canReserveOffer) {
			showDialog(<SwapCriteriaWizard onSuccess={displayConfirmationWindow} />);
		} else {
			showDialog(<ConfirmSwapDialog offer={offer} />);
		}
	}

	if (!userId) {
		return (
			<Button $width={'full'} onClick={navToRegisterPage}>
				{t('registerToSwap')}
			</Button>
		);
	}

	const lowCreditQuote = findLowestCreditQuote(offer.quotes || []);

	if (lowCreditQuote) {
		return (
			<Button
				$width={'full'}
				onClick={openSwapDialog}
				dangerouslySetInnerHTML={{
					__html: t('swapFor', { count: lowCreditQuote.credits, postProcess: 'md' }),
				}}
			/>
		);
	}

	return (
		<Button $width={'full'} onClick={openPointSaleDialog}>
			{t('getPoints')}
		</Button>
	);
};
