import React from 'react';
import { Dialog } from '@/components/Dialog';
import { CategoriesList } from './CategoriesList';

export const CategoryDialog = () => {
	return (
		<Dialog canClose={true} title={'Browse categories'}>
			<CategoriesList />
		</Dialog>
	);
};
