import React, { useContext, useState } from 'react';
import { Dialog } from './Dialog';
import { PhoneVerifyWizard } from './PhoneVerifyWizard';
import { Profile } from '@smartswap/client-api';
import { getMyProfile } from '@/services/PersonalService';
import { VerifyEmailDialog } from './VerifyEmailDialog';
import { useTranslation } from 'react-i18next';
import { DialogContext } from '@/contexts/DialogContext';
import { CreateOfferDialog } from './CreateOfferDialog';

interface CreateOfferCriteriaWizardProps {
	profile?: Profile;
	onSuccess?: () => void;
}

export const CreateOfferCriteriaWizard: React.FC<CreateOfferCriteriaWizardProps> = ({ onSuccess, profile: profileProp }) => {
	const [profile, setProfile] = useState<Profile | null>(profileProp || null);
	const { t } = useTranslation('account');
	const { showDialog } = useContext(DialogContext);

	if (!profile || !profile.flags) {
		updateProfile();
		return null;
	}

	if (profile.flags.canCreateOffer) {
		onSuccess && onSuccess();
	}

	function updateProfile() {
		getMyProfile().then(setProfile);
	}

	if (!profile.flags.isEmailVerified) {
		return <VerifyEmailDialog email={profile?.email} />;
	}

	if (!profile.phone) {
		return (
			<Dialog title={t('addPhoneDialog.title')} overflow={true}>
				<PhoneVerifyWizard onSuccess={updateProfile} />
			</Dialog>
		);
	}

	showDialog(<CreateOfferDialog/>)

	return <div />;
};
