import React, { useContext } from 'react';
import { RemindersDropdownContent } from './Reminders/RemindersDropdownContent';
import { NotificationIcon } from './Reminders/NotificationIcon';
import { Bell } from 'react-feather';
import { iconDefaultProps } from '@/Icons/IconDefaultProps';
import { RemindersDropdown } from './Reminders/RemindersDropdown';
import { RemindersContext } from './RemindersContext';

export const RemindersLink: React.FC = () => {
	const { nonAckedTransactions } = useContext(RemindersContext);

	return (
		<RemindersDropdown content={<RemindersDropdownContent />}>
			<NotificationIcon notifications={nonAckedTransactions > 0}>
				<Bell {...iconDefaultProps} />
			</NotificationIcon>
		</RemindersDropdown>
	);
};
