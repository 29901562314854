import React from 'react';
import { Offer } from '@smartswap/client-api';
import styled, { css } from 'styled-components';
import { UpTriangle } from '@/Icons';
import { useOfferUpvote } from '@/hooks/useOfferUpvote';
import { Button } from '@/components/Button';
import { useTranslation } from 'react-i18next';

interface ButtonProps {
	$active?: boolean;
}

const ButtonSmall = styled.button<ButtonProps>`
	min-width: 32px;
	min-height: 32px;
	background: var(--upvote-color-background);
	border: none;
	position: absolute;
	right: 8px;
	bottom: 8px;
	display: flex;
	flex-flow: column;
	align-items: center;
	border-radius: var(--border-radius-small);
	padding: 4px;
	font-family: InterUI, sans-serif;
	justify-content: space-between;
	line-height: 1;

	color: var(--upvote-color-text);
	font-size: 12px;

	&:enabled {
		cursor: pointer;

		&:hover {
			color: var(--upvote-active-color);
			box-shadow: 0 0 0 2px var(--upvote-active-color);

			svg {
				fill: var(--upvote-active-color);
			}
		}
	}

	svg {
		fill: #aeb7c9;
	}

	${(p) =>
		p.$active &&
		css`
			color: var(--upvote-active-color);

			svg {
				fill: var(--upvote-active-color);
			}
		`}
`;

const ButtonLarge = styled(Button)<ButtonProps>``;

interface UpvoteButtonProps {
	offer: Offer;
}

export function UpvoteButtonSmall({ offer }: UpvoteButtonProps) {
	const { canUpvote, upvoteValue, toggleVote, hasUpVoted } = useOfferUpvote(offer);

	return (
		<ButtonSmall
			type={'button'}
			disabled={!canUpvote}
			onClick={(event) => {
				event.preventDefault();
				toggleVote();
			}}
			$active={hasUpVoted}
		>
			<UpTriangle />
			<span>{upvoteValue}</span>
		</ButtonSmall>
	);
}

export function UpvoteButtonLarge({ offer }: UpvoteButtonProps) {
	const { canUpvote, upvoteValue, toggleVote, hasUpVoted } = useOfferUpvote(offer);
	const { t } = useTranslation('common');

	return (
		<ButtonLarge
			// $width={'full'}
			$variant={'outline'}
			type={'button'}
			disabled={!canUpvote}
			onClick={(event) => {
				event.preventDefault();
				toggleVote();
			}}
			$active={hasUpVoted}
		>
			{t('upvote')}&nbsp;({upvoteValue})
		</ButtonLarge>
	);
}
