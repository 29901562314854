import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SidebarGroup } from '@/components/Categories/CategoriesList';
import { SidebarItem } from '@/components/SidebarItem';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { Chat, Reminder } from '@smartswap/client-api';
import { getChats } from '@/services/ChatService';
import { screenSize } from '@/ScreenSize';
import { getParticipantNames } from '@/lib/chat';
import { getUserId } from '@/services/UserService';
import { RemindersContext } from '@/components/RemindersContext';
import { ReminderCounter } from '@/components/Reminders/ReminderCounter';
import { find } from 'lodash';
import { PushContext } from '@/contexts/PushContext';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@/components/Avatar/Avatar';

const Wrapper = styled.div`
	height: calc(100vh - 120px);
	overflow-y: auto;
	margin: -16px -8px;

	@media screen and ${screenSize.sm} {
		height: calc(100vh - 60px);
		margin: 0;
	}
`;

const Header = styled.div`
	padding: 16px;
	font-size: 20px;
`;

const ChatSidebarItem = styled(SidebarItem)`
	min-height: 54px;
`;

const EmptyChat = styled.div`
	font-family: InterUI, sans-serif;
	padding: calc(var(--step) * 4);
	padding-top: 0;
	padding-bottom: 0;

	@media screen and ${screenSize.sm} {
		padding: 0 calc(var(--step) * 2);
	}
`;

const ChatListReminder = styled(ReminderCounter)`
	margin-left: auto;
	height: 4px;

	circle {
		fill: var(--color-brand);
	}
`;

interface ChatListItemProps {
	chat: Chat;
	reminder?: Reminder;
}

const ChatListItem: React.FC<ChatListItemProps> = ({ chat, reminder }) => {
	const userId = getUserId();
	const participantNames = getParticipantNames(chat, userId);

	return (
		<NavLink to={{ pathname: `/messages/${chat.id}` }} state={{ chat }}>
			<ChatSidebarItem>
				<Avatar name={participantNames || ''} />
				<span style={{ marginLeft: '12px' }}>{participantNames}</span>
				{reminder && <ChatListReminder />}
			</ChatSidebarItem>
		</NavLink>
	);
};

export const UserList: React.FC = () => {
	const [chats, setChats] = useState<Chat[]>();
	const location = useLocation();
	const { getRemindersForChat } = useContext(RemindersContext);
	const params = useParams();
	const chatId = params?.chatId;
	const currentChatHasStarted = chats?.some((chat) => chat.id === chatId);
	const passedInChat = location?.state?.chat;
	const { addHandler, removeHandler } = useContext(PushContext);
	const { t } = useTranslation('messages');

	const update = useCallback(() => {
		getChats()
			.then((r) => r.chats)
			.then(setChats);
	}, []);

	useEffect(update, [update]);
	useEffect(() => {
		addHandler('ChatMessageCreated', update);
		return () => removeHandler('ChatMessageCreated', update);
	}, [addHandler, removeHandler, update]);

	return (
		<Wrapper>
			<Header>{t('messages')}</Header>
			<SidebarGroup>
				{!passedInChat && chats?.length === 0 && <EmptyChat>{t('noConversations')}</EmptyChat>}
				{!currentChatHasStarted && passedInChat && <ChatListItem chat={passedInChat} />}
				{chats?.map((chat) => {
					const reminder = find(getRemindersForChat(chat.id), (r) => !r.isAcked);
					return <ChatListItem key={chat.id} chat={chat} reminder={reminder} />;
				})}
			</SidebarGroup>
		</Wrapper>
	);
};
