import React, { useMemo } from 'react';
import styled from 'styled-components';
import { RemoveIcon } from '@/Icons';
import { ImageProps } from '@/components/ImageUpload/ImageUpload';

const ImageRemoveIcon = styled(RemoveIcon)`
	position: absolute;
	z-index: 1;
	top: 6px;
	right: 6px;
`;

export const ImageTile = styled.div`
	width: 127px;
	height: 127px;
	border-radius: 8px;
	font-size: 14px;
	color: #778699;
	cursor: pointer;
	box-sizing: border-box;
	overflow: hidden;
	position: relative;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		transform: translate3d(0px, 0px, 0.1px);
	}
`;


interface ImageTilesProps {
	images: ImageProps[];
	onRemove: (item: ImageProps) => void;
}

export const ImageTiles: React.FC<ImageTilesProps> = ({ images, onRemove }) => {
	return (
		<>
			{images.map((image) => {
				return (
					<ImageTile key={image.src} onClick={() => onRemove(image)}>
						<ImageRemoveIcon />
						<img alt={'thumbnail of upload'} src={image.src} />
					</ImageTile>
				);
			})}
		</>
	);
};
