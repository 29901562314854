import { Button } from '@/components/Button';
import * as React from 'react';
import { useCallback, useContext, useState } from 'react';
import { Offer } from '@smartswap/client-api';
import { reserveOffer } from '@/services/MarketService';
import { Dialog } from '@/components/Dialog';
import styled from 'styled-components';
import { DeliveryOptions } from './DeliveryOptions';
import { DialogContext } from '@/contexts/DialogContext';
import { TradeCreatedDialog } from '@/components/TradeCreatedDialog';
import { useTranslation } from 'react-i18next';
import { TradeFailedDialog } from '@/components/OfferPage/TradeFailedDialog';

const OfferPreview = styled.div`
	background: #f0f4f8;
	border-radius: 8px;
	display: flex;
	flex-flow: row;
	justify-content: flex-start;
	align-items: center;
	padding: 18px;
	margin-bottom: 32px;
`;

const OfferImage = styled.img`
	height: 52px;
	margin-right: var(--step);
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-flow: row;
	margin-top: 48px;

	& > * {
		&:not(:last-child) {
			margin-right: 12px;
		}
	}
`;

interface ConfirmSwapDialogProps {
	offer: Offer;
}

export const ConfirmSwapDialog: React.FC<ConfirmSwapDialogProps> = ({ offer }) => {
	const { popDialog, showDialog } = useContext(DialogContext);
	const [barcode, setBarcode] = useState<string>();
	const [loading, setLoading] = useState<boolean>();
	const { t } = useTranslation(['checkout', 'errors']);

	const reserve = useCallback(() => {
		if (!barcode || !offer) {
			console.log('barcode', barcode);
			console.log('offer', offer);
			return;
		}

		setLoading(true);
		reserveOffer(offer.id, barcode)
			.then(() => {
				showDialog(<TradeCreatedDialog />);
			})
			.catch(async (response) => {
				const responseBody = await response.json();
				showDialog(<TradeFailedDialog><p>{t(responseBody.error, {ns: 'errors'})}</p></TradeFailedDialog>);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [barcode, offer, showDialog, t])

	if (!offer) {
		return null;

	}

	const imgSrc = offer?.media?.[0]?.src;

	return (
		<Dialog title={t('confirmSwap')}>
			<OfferPreview>
				{imgSrc && <OfferImage src={imgSrc} />}
				{offer.details.title}
			</OfferPreview>
			<DeliveryOptions offer={offer} onSelectionChange={setBarcode} />
			<ButtonWrapper>
				<Button disabled={loading} $width={'full'} onClick={reserve}>
					{t('swap')}
				</Button>
				<Button disabled={loading} $width={'full'} $variant="outline" onClick={popDialog}>
					{t('cancel')}
				</Button>
			</ButtonWrapper>
		</Dialog>
	);
};
