import styled, { css } from 'styled-components';
import { Card } from '@/components/Card/Card';

export const DropdownList = styled(Card)`
	padding: 8px;
`;

interface DropdownListItemProps {
	$active?: boolean;
}

const activeItemStyle = css`
    background: var(--color-brand-pastel);
`;

export const DropdownListItem = styled.div<DropdownListItemProps>`
	padding: 8px;
	border-radius: 5px;
	
	${p => p.$active && activeItemStyle}
	
	&:hover {
		${activeItemStyle}
	}
`;
