import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { GridView } from '@/components/GridView';
import { GridGroup, GridHeader } from '@/components/GridGroup';
import { OffersGrid } from '@/components/OffersGrid';
import { Button } from '@/components/Button';
import styled from 'styled-components';
import { useQuery } from '@/hooks/useQuery';
import InfiniteScroll from 'react-infinite-scroller';
import { useFeed } from '@/hooks/useFeed';
import { FriendInviteCard } from '@/components/FriendInviteCard';
import { useTranslation } from 'react-i18next';
import { TopBackgroundIcon } from '@/Icons/marketing';
import { textShadow } from '@/styles/GlobalStyle';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { screenSize } from '@/ScreenSize';
import { OfferSortDropdown } from '@/components/FeedPage/Filters/OfferSortDropdown';
import { SearchGetStateEnum } from '@smartswap/client-api';

const LoadMoreButton = styled(Button)`
	margin-top: 32px;
	width: 100%;
`;

interface FeedPageProps {
	defaultCategory?: string;
}

const BannerCard = styled.div`
	width: 100%;
	position: relative;
	margin: -16px -16px 90px;
	display: flex;
`;

const BannerContent = styled.div`
	font-family: InterUI, 'sans-serif';
	margin: 16px;
	max-width: 800px;
	font-weight: normal;
	color: white;
	font-size: 24px;
	z-index: 1;
	${textShadow(2)}
`;

interface TopBackgroundProps {
	$isDesktop?: boolean;
}

const TopDesktopBackground = styled(TopBackgroundIcon).attrs<TopBackgroundProps>((p) => ({
	preserveAspectRatio: p.$isDesktop ? 'none' : 'xMidYMax slice',
}))`
	--top-gap: 130px;
    position: absolute;
    overflow: visible;
    bottom:  calc(var(--top-gap) * -1);
    width: calc(100% + 20%);
    height: calc(100% + var(--top-gap));
`;

export const FeedPage: React.FC<FeedPageProps> = ({ defaultCategory }) => {
	const {
		offers,
		loadMore,
		setCategory,
		hasMore,
		setQuery,
		setWarehouse,
		sort,
		setSort,
		setState
	} = useFeed();
	const { categorySlug = defaultCategory, warehouse } = useParams();
	const query = useQuery();
	const searchQuery = query.get('search') || null;
	const [autoLoad, setAutoLoad] = useState<boolean>(false);
	const { t } = useTranslation(['common', 'categories', 'homepage']);
	const isDesktop = useMediaQuery(`screen and ${screenSize.md}`);
	const location = useLocation();

	const showPromoBar = location.pathname === '/';

	useEffect(() => {
		setCategory(categorySlug || '');
		setWarehouse(warehouse);
		setAutoLoad(false);
	}, [categorySlug, setCategory, setWarehouse, warehouse]);

	useEffect(() => {
		setState(SearchGetStateEnum.Published);
	}, []);

	useEffect(() => {
		setQuery(searchQuery || '');
	}, [searchQuery, setQuery]);

	function startAutoload() {
		setAutoLoad(true);
	}

	let header = t(`categories:${categorySlug || warehouse || 'allCategories'}`);

	if (searchQuery) {
		header = t('resultsFor', { searchQuery });
	}

	return (
		<GridView>
			{showPromoBar && (
				<BannerCard>
					<TopDesktopBackground $isDesktop={isDesktop} />
					<BannerContent>{t('homepage:headingContent')}</BannerContent>
				</BannerCard>
			)}

			<GridGroup style={{ zIndex: 1, position: 'relative' }}>
				<GridHeader>
					<div style={{ flex: 1 }}>{header}</div>
					<div style={{ marginBottom: '2px' }}>
						<OfferSortDropdown currentSort={sort} onSelect={setSort} />
					</div>
					{/*<CountryFilter currentCountry={country} onSelect={setCountry} />*/}
					{/*<GradeFilter currentGrade={grade} onSelect={setGrade} />*/}
				</GridHeader>

				<InfiniteScroll loadMore={loadMore} hasMore={autoLoad && hasMore}>
					<OffersGrid offers={offers} viewOnly={categorySlug === '_archive'}>
						<FriendInviteCard />
					</OffersGrid>
				</InfiniteScroll>
				{hasMore && !autoLoad && (
					<LoadMoreButton onClick={startAutoload}>{t('loadMoreItems', { ns: 'common' })}</LoadMoreButton>
				)}
			</GridGroup>
		</GridView>
	);
};
