export function updateLocalStorageObject(key: string, obj: object) {
	const currentObject = getLocalStorageObject(key);
	const newObject = Object.assign(currentObject, obj);
	window.localStorage.setItem(key, JSON.stringify(newObject));
}

export function getLocalStorageObject(key: string) {
	const currentObjectString = window.localStorage.getItem(key) || `{}`;
	return JSON.parse(currentObjectString);
}
