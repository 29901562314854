import React, { ReactNode, useLayoutEffect, useMemo, useRef } from 'react';
import { ChatMessage, Offer } from '@smartswap/client-api';
import styled from 'styled-components';
import { getUserId } from '@/services/UserService';
import { MessageBubble, StyledMessageTime } from './MessageBubble';
import { OfferMessageBubble } from './OfferMessageBubble';

interface MessageListProps {
	messages?: ChatMessage[];
	lastOffer?: Offer | null;
}

const Wrapper = styled.div`
	flex: 1;
	display: flex;
	flex-flow: column;
	font-family: 'InterUI';
	font-size: 14px;
	font-weight: normal;
	overflow-y: scroll;
	height: 100%;
	box-sizing: border-box;
	padding: 16px;

	& > *:first-child {
		margin-top: auto;
	}
`;

const AuthorRow = styled.div`
	margin-top: 16px;
`;

export const MessageList: React.FC<MessageListProps> = ({ messages }) => {
	const userId = getUserId();
	const messageList = useRef<HTMLDivElement>(null);

	function scrollToLastMessage() {
		if (messageList && messageList.current) {
			messageList.current.scrollTop = messageList.current.scrollHeight;
		}
	}

	const renderedMessages = useMemo(() => {
		if (!messages) return;

		const messageChildren: ReactNode[] = [];

		messages.forEach((message, index) => {
			const previousMessage = index - 1 >= 0 ? messages[index - 1] : null;
			const nextMessage = index + 1 <= messages.length - 1 ? messages[index + 1] : null;

			const { author, text, time } = message;
			const isFirstInGroup = !previousMessage || previousMessage?.author?.id !== message?.author?.id;
			const isLastInGroup = !nextMessage || nextMessage?.author?.id !== message?.author?.id;
			const isMyMessage = author?.id === userId;

			if (message.offerId) {
				messageChildren.push(
					<OfferMessageBubble
						key={`${message.author} ${message.time.toISOString()} ${message.offerId}`}
						$isMy={isMyMessage}
						offerId={message.offerId}
					/>,
				);
			}

			messageChildren.push(
				<React.Fragment key={`${message.author} ${message.time.toISOString()}`}>
					{isFirstInGroup && !isMyMessage && <AuthorRow>{author?.displayName}</AuthorRow>}
					<MessageBubble $isMy={isMyMessage}>{text}</MessageBubble>
					{isLastInGroup && <StyledMessageTime $isMy={isMyMessage} dateTime={time} />}
				</React.Fragment>,
			);
		});

		return messageChildren;
	}, [messages, userId]);

	useLayoutEffect(scrollToLastMessage, [renderedMessages]);
	useLayoutEffect(scrollToLastMessage, []);

	return <Wrapper ref={messageList}>{renderedMessages}</Wrapper>;
};
