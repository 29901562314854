import React, { CSSProperties, useCallback } from 'react';
import styled from 'styled-components';
import { MemberAddress, PersonalApi } from '@smartswap/client-api';
import { config } from '@/configuration';
import { CloseIcon } from '@/Icons';
import { screenSize } from '@/ScreenSize';

const Wrapper = styled.label`
	font-weight: 400;
	margin-top: 0.5em;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	height: 3em;
	padding: 0 0.5em;

	border-radius: 8px;
	background: var(--color-gray-100);

	input[type='radio'] {
		margin-right: 0.5em;
		--form-color: var(--color-gray-300);
		appearance: none;

		font: inherit;
		color: var(--form-color);
		width: 1em;
		height: 1em;
		//border: 1px solid var(--form-color);
		border-radius: 50%;
		box-shadow: inset 0 0 0 1px var(--form-color);
		background: var(--color-default);

		&:checked {
			box-shadow: inset 0 0 0 0.3em var(--color-primary);
		}
	}
`;

interface AddressRowProps {
	className?: string;
	style?: CSSProperties;
	address: MemberAddress;
	onRemove?: (id: string) => void;
	onChange?: (id: string) => void;
	isSelected?: boolean;
}

const Close = styled(CloseIcon)`
	cursor: pointer;
	margin-right: auto;
`;

const LeftSpan = styled.span`
	padding: 0 1em 0 0;
	flex: 1;

	@media screen and ${screenSize.mobile} {
		padding: 0 5px;
		font-size: 0.8em;
	}
`;

const api = new PersonalApi(config);

export const AddressRow: React.FC<AddressRowProps> = ({
	address,
	className,
	style,
	onRemove,
	isSelected,
	onChange,
}) => {
	async function handleRemove() {
		await api.myAddressesIdDelete({ id: address.id });
		onRemove && onRemove(address.id);
	}

	const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			api.myAddressesDefaultIdPut({ id: address.id }).then(() => {
				onChange?.(address.id);
			});
		}
	}, []);

	return (
		<Wrapper className={className} style={style}>
			<input onChange={handleChange} checked={isSelected} name={'defaultAddress'} type={'radio'} />
			<LeftSpan>{address.title}</LeftSpan>
			<Close onClick={handleRemove} />
		</Wrapper>
	);
};
