import { useNavigate, useParams } from 'react-router-dom';
import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Offer, OfferMedia } from '@smartswap/client-api';
import 'swiper/css';
import 'swiper/css/pagination';
import Avatar from 'react-avatar';
import { Helmet } from 'react-helmet';
import { Button } from '@/components/Button';
import { Card, CardFooter } from '@/components/Card/Card';
import { getOffer, getOfferChat } from '@/services/Offer';
import { screenSize } from '@/ScreenSize';
import { MessagesIcon } from '@/Icons';
import { getUserId } from '@/services/UserService';
import { Quote } from '@/components/OfferPage/Quote';
import { SwapButton } from '@/components/OfferPage/SwapButton';
import { getGrade } from '@/ItemGrades';
import { ShareButtons } from '@/components/ShareButtons';
import { getCanonicalUrl } from '@/lib/url';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { UpvoteButtonLarge } from '@/components/Upvote/UpvoteButton';

const Wrapper = styled.div`
	@media screen and ${screenSize.sm} {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
`;

const Container = styled.div`
	@media screen and ${screenSize.sm} {
		margin-left: 20px;
	}
`;

const InfoCard = styled(Card)`
	width: 100%;

	h2 {
		margin-top: 0;

		letter-spacing: -0.4px;
		font-size: 24px;
		font-weight: 600;
	}

	@media screen and ${screenSize.mobile} {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
`;

const InfoCardText = styled.div`
	box-sizing: border-box;
	padding: calc(var(--step) * 4) calc(var(--step) * 4);
`;

const Description = styled.div`
	font-size: 14px;
	font-family: 'InterUI', sans-serif;
	color: rgba(119, 134, 153, 1);
	font-weight: 400;
	line-height: 1.57;
`;

const Image = styled.img`
	width: 100%;
	height: 100%;
`;

const ImageCard = styled(Card)<{ src: string }>`
	width: 100%;
	margin-bottom: 24px;

	& > * {
		padding: 0;
	}
`;

const MessageButton = styled(Button).attrs(() => ({
	$variant: 'outline',
}))`
	display: flex;
	align-items: center;

	& :first-child {
		margin-right: 8px;
	}
`;

const InfoCardOwnerRow = styled.div`
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	padding: 1.57em calc(var(--step) * 4);
`;

const InfoCardOwner = styled.div`
	font-size: 0.8em;
	font-family: 'InterUI', sans-serif;
	color: rgba(119, 134, 153, 1);
	font-weight: 400;
	line-height: 1.57;

	display: flex;
	flex-flow: row;
	align-items: center;
`;

const SwiperWrapper = styled(Card)`
	width: 100%;
	border-top-left-radius: var(--border-radius);
	border-top-right-radius-radius: var(--border-radius);
	overflow: hidden;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;

	.swiper-pagination-bullet {
		--swiper-pagination-color: var(--color-brand);
	}
`;

const InfoCardFooter = styled(CardFooter)`
	background: #ffffff;
	padding-top: calc(var(--step) * 3);

	${Button} {
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;

		&:not(:last-child) {
			margin-bottom: 12px;
		}
	}
`;

interface ImageGalleryProps {
	media: OfferMedia[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ media }) => {
	return (
		<React.Fragment>
			{media.map((med) => (
				<ImageCard as={'img'} key={med.src} src={med.src} />
			))}
		</React.Fragment>
	);
};

const ImageSwiper: React.FC<ImageGalleryProps> = ({ media }) => {
	return (
		<SwiperWrapper>
			<Swiper autoHeight={false} pagination={{ type: 'bullets' }} modules={[Pagination]}>
				{media.map((med) => (
					<SwiperSlide key={med.uuid}>
						<Image key={med.src} src={med.src} />
					</SwiperSlide>
				))}
			</Swiper>
		</SwiperWrapper>
	);
};

const MobileOnly = styled.div`
	display: none;
	@media screen and ${screenSize.mobile} {
		display: block;
	}
`;

const SmOnly = styled.div`
	display: none;
	@media screen and ${screenSize.sm} {
		display: block;
	}
`;

const FlexEqual = styled.div`
	display: flex;
	gap: 12px;

	& > * {
		flex: 1;
	}
`;

export const ItemPage: React.FC = () => {
	const { itemId } = useParams();
	const [offer, setOffer] = useState<Offer>();
	const navigate = useNavigate();
	const userId = getUserId();
	const isMyItem = offer && offer?.owner?.id === userId;
	const { t } = useTranslation('item-page');

	useEffect(refreshOffer, [itemId]);

	function refreshOffer() {
		//TODO: feedback
		if (!itemId) return;
		getOffer(itemId).then(setOffer);
	}

	if (!offer) {
		return null;
	}

	function handleUpdate() {
		refreshOffer();
	}

	function beginMessage() {
		if (!offer) return;

		getOfferChat(offer.id).then((chat) => {
			navigate(`/messages/${chat.id}`, { state: { chat, offer } });
		});
	}

	const gradeKey = getGrade(offer.details.grade)?.description;
	const gradeText = t(`conditions.${gradeKey}`, { ns: 'common' });
	const ogImage = offer?.media?.[0]?.src;

	const mediaItems = offer.media || [];

	return (
		<Wrapper>
			<Helmet>
				<title>{offer?.details.title}</title>
				<meta name="description" content={offer?.details.description} />
				<link rel="canonical" href={getCanonicalUrl()} />
				<meta property="og:url" content={getCanonicalUrl()} />
				{ogImage && <meta property="og:image" content={ogImage} />}
			</Helmet>
			{/*{state === State.Swapped && <TradeCreatedDialog />}*/}
			<MobileOnly>
				<ImageSwiper media={mediaItems} />
			</MobileOnly>

			<SmOnly>
				<ImageGallery media={mediaItems} />
			</SmOnly>
			<Container>
				<InfoCard>
					<InfoCardText>
						<h2>{offer.details.title}</h2>
						{gradeText && <Description style={{ marginBottom: '16px' }}>{gradeText}</Description>}
						<Description>{offer.details.description}</Description>
					</InfoCardText>
					<InfoCardOwnerRow>
						<InfoCardOwner>
							{/*{!offer?.warehouse && (*/}
							<>
								<Avatar size={'34px'} round={'1000px'} name={offer?.owner?.displayName} />
								<span style={{ marginLeft: '8px' }}>{offer?.owner?.displayName}</span>
							</>
							{/*)}*/}
							{/*{offer?.warehouse && (*/}
							{/*	<img*/}
							{/*		style={{ maxHeight: '32px' }}*/}
							{/*		src={`/icons/warehouse/${offer?.warehouse?.slug}.svg`}*/}
							{/*		alt={`${offer?.warehouse?.name} avatar`}*/}
							{/*	/>*/}
							{/*)}*/}
						</InfoCardOwner>
						{userId && !isMyItem && (
							<MessageButton onClick={beginMessage}>
								<MessagesIcon />
								{t('message')}
							</MessageButton>
						)}
					</InfoCardOwnerRow>
					<InfoCardFooter>
						{!isMyItem && (
							<>
								{userId && !isMyItem && offer?.state !== 'reserved' && <Quote offer={offer} />}
								{offer?.state === 'reserved' ? (
									<Button $width={'full'} disabled={true}>
										{t('reserved')}
									</Button>
								) : (
									<SwapButton offer={offer} userId={userId} onUpdate={handleUpdate} />
								)}
							</>
						)}

						<FlexEqual>
							<UpvoteButtonLarge offer={offer} />
							<ShareButtons>{t('share')}</ShareButtons>
						</FlexEqual>
					</InfoCardFooter>
				</InfoCard>
			</Container>
		</Wrapper>
	);
};
