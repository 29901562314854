import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from './Dialog';

export function UpdateEmailDialog({ email }: { email: string }) {
	const { t } = useTranslation('account');

	return (
		<Dialog title={t('updateEmailDialog.title')}>
			<span dangerouslySetInnerHTML={{ __html: t('updateEmailDialog.content', { email }) }} />
		</Dialog>
	);
}
