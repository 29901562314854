import React, { useContext } from 'react';
import styled from 'styled-components';
import { RemindersContext } from '@/components/RemindersContext';
import { ReminderCounter } from './ReminderCounter';
import { useTranslation } from 'react-i18next';

interface NotificationRow {
	acked: boolean;
}

const NotificationRowProps = styled.div<NotificationRow>`
	width: 100%;
	padding: 16px;
	border-bottom: 1px solid #d7e1ea;
	background-color: ${(p) => (p.acked ? 'var(--color-default)' : 'var(--color-gray-2)')};
`;

const Header = styled.div`
	padding: 22px 24px;
	border-bottom: 1px solid #d7e1ea;
	justify-content: space-between;
	display: flex;
	flex-flow: row;
	position: sticky;
`;

export const RemindersDropdownContent: React.FC = () => {
	const { transactionReminders, nonAckedTransactions, acknowledgeReminders } = useContext(RemindersContext);
	const { t } = useTranslation('account');

	return (
		<>
			<Header>
				<div>{t('notifications')}</div>
				<ReminderCounter>{nonAckedTransactions}</ReminderCounter>
			</Header>
			{transactionReminders.map((reminder) => {
				const { shipmentReady, shipmentCollected } = reminder.datum;

				return (
					<NotificationRowProps
						key={reminder.id}
						acked={reminder.isAcked}
						onClick={() => acknowledgeReminders([reminder.id])}
					>
						{shipmentReady && (
							<>
								{shipmentReady?.title} is ready for delivery
								<br />
								Use door code: {shipmentReady?.doorcode}
							</>
						)}
						{shipmentCollected && <>{shipmentCollected?.title} has been collected</>}
					</NotificationRowProps>
				);
			})}
		</>
	);
};
