import { ProfileDropdown } from './ProfileDropdown';
import { Dropdown } from './Dropdown';
import React, { useContext } from 'react';
import { MemberContext } from '@/contexts/MemberContext';
import { Button } from './Button';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { AvatarSheet } from './AvatarSheet/AvatarSheet';
import Avatar from 'react-avatar';
import { StarIcon } from '../Icons';
import { ChatLink } from './ChatLink';
import { RemindersLink } from './RemindersLink';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;
	z-index: 2;

	& > * {
		display: flex;
	}

	& > *:not(:first-child) {
		margin-left: 16px;
	}

	> a:not(:first-child) {
		margin-left: 16px;
	}

	${Button} {
		padding-left: 16px;
		padding-right: 16px;
	}
`;

const AvatarIconWrapper = styled.div`
	position: relative;
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;

	& > :not(:first-child) {
		margin-left: 6px;
	}
`;

const StyledAvatar = styled(Avatar)`
	position: absolute;
	right: 0;
`;

const PointArea = styled.div`
	height: 36px;
	padding-right: 44px;
	border-radius: 60px;
	background: #f2f2ff;
	display: flex;
	align-items: center;
	padding-left: 12px;
	justify-content: center;
	color: #696cff;

	> :not(:first-child) {
		margin-left: 8px;
	}
`;

export const ProfileMenu = () => {
	const memberContext = useContext(MemberContext);
	const { t } = useTranslation('auth');

	if (memberContext.fullName) {
		return (
			<Wrapper>
				<RemindersLink />
				<ChatLink />
				<Dropdown content={<ProfileDropdown />}>
					<AvatarIconWrapper>
						<PointArea>
							<StarIcon style={{ opacity: 0.4 }} />
							<span>{memberContext.balance}</span>
						</PointArea>
						<StyledAvatar size={'34'} round={'1000px'} name={memberContext.displayName} />
					</AvatarIconWrapper>
				</Dropdown>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<Link to={'/login'}>
				<Button $variant={'outline'}>{t('loginShort')}</Button>
			</Link>
			<Link to={'/register'}>
				<Button>{t('registerShort')}</Button>
			</Link>
		</Wrapper>
	);
};
