import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputField, SelectField } from '@/components/Input';
import { DialogContext } from '@/contexts/DialogContext';
import { Dialog } from '@/components/Dialog';
import { Button } from '@/components/Button';
import { usePersonalAddresses } from '@/hooks/api/usePersonalAddresses';
import { useCountries } from '@/hooks/api/useCountries';
import { useMemberContext } from '@/contexts/MemberContext';

const Wrapper = styled.div`
	font-family: 'InterUI', sans-serif;
	font-weight: normal;
`;

const Row = styled.div`
	display: flex;
	flex-flow: row;
	width: 100%;

	&:not(:last-child) {
		margin-bottom: 1em;
	}

	& > * {
		flex: 1;

		&:not(:first-child) {
			margin-left: 16px;
		}
	}

	label {
		margin: 0;
	}
`;

interface AddressDetailFields {
	city: string;
	country: string;
	lines: string;
	postcode: string;
	title: string;
}

interface AddressDetailsFormProps {
	formik: FormikProps<AddressDetailFields>;
	onCancel?: () => void;
}

const AddressDetailsForm: React.FC<AddressDetailsFormProps> = (props) => {
	const { t } = useTranslation('address-dialog');
	const { countries } = useCountries();

	function onCancel() {
		props.onCancel?.();
	}

	return (
		<form onSubmit={props.formik.handleSubmit}>
			<Row>
				<InputField type={'text'} label={t('form.lines')} name={'lines'} />
			</Row>
			<Row>
				<InputField type={'text'} label={t('form.city')} name={'city'} />
				<InputField type={'text'} label={t('form.postcode')} name={'postcode'} />
			</Row>
			<Row>
				<SelectField name={'country'} label={t('form.country')}>
					<option value={''} disabled={true}>
						{t('form.country')}
					</option>
					{countries.map((country) => (
						<option key={country.code} value={country.code}>
							{country.name}
						</option>
					))}
				</SelectField>
			</Row>
			<Row>
				<Button type={'submit'} disabled={props.formik.isSubmitting}>
					{t('form.addAddress')}
				</Button>
				<Button $variant={'outline'} type={'button'} onClick={onCancel}>
					{t('form.cancel')}
				</Button>
			</Row>
		</form>
	);
};

interface AddAddressDialogProps {
	onComplete?: () => void;
}

export const AddAddressDialog: React.FC<AddAddressDialogProps> = ({ onComplete }) => {
	const { popDialog } = useContext(DialogContext);
	const { addAddress } = usePersonalAddresses();
	const memberContext = useMemberContext();
	const { t } = useTranslation('address-dialog');

	const formFieldsValidation = useMemo(
		() =>
			Yup.object().shape({
				lines: Yup.string()
					.min(2, t('validation.characterCountRequired', { count: 2 }))
					.required(t('validation.isRequired', { name: 'lines' })),
				city: Yup.string()
					.min(2, t('validation.characterCountRequired', { count: 2 }))
					.required(t('validation.isRequired', { name: 'city' })),
				postcode: Yup.number()
					.min(5, t('validation.characterCountRequired', { count: 2 }))
					.required(t('validation.isRequired', { name: 'postcode' })),
				country: Yup.string()
					.required(t('validation.isRequired', { name: 'country' })),
			}),
		[t],
	);

	async function onSubmit(form: AddressDetailFields) {
		const title = form.lines;
		await addAddress({ ...form, title, id: '' });
		onComplete?.();
	}

	function onCancel() {
		popDialog();
	}

	return (
		<Dialog title={t('title')}>
			<Wrapper>
				<Formik
					validateOnBlur={true}
					validateOnChange={false}
					onSubmit={onSubmit}
					validationSchema={formFieldsValidation}
					initialValues={
						{
							city: '',
							country: memberContext.country || 'EE',
							postcode: '',
							lines: '',
							title: '',
						} as AddressDetailFields
					}
				>
					{(props) => <AddressDetailsForm formik={props} onCancel={onCancel} />}
				</Formik>
			</Wrapper>
		</Dialog>
	);
};
