import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { load } from 'js-yaml';
import { createProcessor } from '@/lib/marked-plugin';
import { getLocalStorageObject, updateLocalStorageObject } from '@/lib/localStorage';

const preferences = getLocalStorageObject('preferences');

i18n.use(backend)
	.use(initReactI18next)
	.use(createProcessor())
	.init({
		debug: false,
		lng: preferences.locale || 'et',
		supportedLngs: ['et', 'en', 'ru', 'fi'],
		// fallbackLng: 'en',
		ns: ['common', 'auth'],
		defaultNS: 'common',
		load: 'currentOnly',
		// postProcess: ['marked'],
		interpolation: {
			escapeValue: false, // Let react decide
		},
		react: {
			// useSuspense: false,
		},
		backend: {
			expirationTime: 1 * 24 * 60 * 60 * 1000, // 7 days
			loadPath: `/locales/{{lng}}/{{ns}}.yaml?v=${process.env.REACT_APP_APP_VERSION}`,
			parse: (data: string) => load(data),
		},
	});

i18n.on('languageChanged', (lng) => {
	updateLocalStorageObject('preferences', { locale: lng });
});

// i18n.loadNamespaces([
// 	'account',
// 	'add-item',
// 	'address-dialog',
// 	'auth',
// 	'card-dialog',
// 	'categories',
// 	'checkout',
// 	'common',
// 	'faq',
// 	'footer',
// 	'homepage',
// 	'item-page',
// 	'messages',
// 	'minimalism',
// 	'my-items',
// 	'point-purchase',
// 	'profile',
// 	'register',
// 	'search',
// ]);

export default i18n;
