import React, { FC, useEffect, useState } from 'react';
import { Card, Profile } from '@smartswap/client-api';
import { getMyProfile } from '@/services/PersonalService';
import { Dialog } from '@/components/Dialog';
import { getMyCards } from '@/services/PaymentService';
import { AddCardDialog } from '@/components/AddCardDialog';
import { PhoneVerifyWizard } from '@/components/PhoneVerifyWizard';
import { useTranslation } from 'react-i18next';
import { AddAddressDialog } from '@/components/Address/AddAddressDialog';

interface SwapCriteriaWizardProps {
	profileProp?: Profile;
	onSuccess?: () => void;
}

export const SwapCriteriaWizard: FC<SwapCriteriaWizardProps> = ({ profileProp, onSuccess }) => {
	const [profile, setProfile] = useState<Profile | null>(profileProp || null);
	const [cards, setCards] = useState<Card[] | null>();
	const { t } = useTranslation('account');

	useEffect(updateCards, []);

	function updateProfile() {
		getMyProfile().then(setProfile);
	}

	function updateCards() {
		getMyCards().then((cardData) => setCards(cardData.cards));
	}

	function updateAll() {
		updateProfile();
		updateCards();
	}

	if (!profile) {
		updateProfile();
		return null;
	}

	if (profile?.flags?.canReserveOffer) {
		onSuccess && onSuccess();
	}

	if (!profile?.flags?.isEmailVerified) {
		return (
			<Dialog title={t('verifyEmailDialog.title')}>
				<span dangerouslySetInnerHTML={{ __html: t('verifyEmailDialog.content', { email: profile.email }) }} />
			</Dialog>
		);
	}

	if (!profile?.phone) {
		return (
			<Dialog title={'Please provide your phone number'}>
				<PhoneVerifyWizard onSuccess={updateProfile} />
			</Dialog>
		);
	}

	if (!profile.defaultAddressId) {
		return (
			<AddAddressDialog onComplete={updateProfile} />
		);
	}

	if (cards?.length === 0) {
		return <AddCardDialog onComplete={updateAll} />;
	}

	return null;
};
