import React, { ComponentProps, FC } from 'react';

export const combineComponents = (...components: FC<any>[]): FC<any> => {
	return components.reduce(
		(AccumulatedComponents, CurrentComponent) => {
			return function CombinedComponents({ children }: ComponentProps<FC<any>>) {
				return (
					<AccumulatedComponents>
						<CurrentComponent>{children}</CurrentComponent>
					</AccumulatedComponents>
				);
			};
		},
		({ children }) => <>{children}</>,
	);
};
