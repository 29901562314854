import { Link } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../Icons/Basic/Logo.svg';
import React from 'react';
import styled from 'styled-components';
import { screenSize } from '@/ScreenSize';
import footerItems from '../footerItems.json';
import { useTranslation } from 'react-i18next';

const Row = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  @media screen and ${screenSize.md} {
    flex-flow: row;
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  grid-area: footer;
  background: var(--footer-color-background);
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-flow: column;
  color: #ffffff;
  font-family: InterUI;
  font-size: 14px;

  padding: 8px 8px;

  @media screen and ${screenSize.md} {
    padding: 64px 64px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  margin: 8px;

  //&:not(:last-child) {
  //  margin: 8px;
  //}

  @media screen and ${screenSize.sm} {
    &:not(:last-child) {
      margin-right: 64px;
    }
  }
  
  @media screen and ${screenSize.tablet} {
	&:first-child {
	  margin-top: 8px;
    }

    &:nth-child(2) {
	  margin-top: 16px;
	}
	
  	margin-top: 48px;
  }
`;

const FooterLink = styled.a`
  display: block;
  color: var(--footer-color-text);
  font-weight: var(--font-weight-medium);

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:hover {
    opacity: 1;
  }
`;

const LogoWrapper = styled.div`
  font-size: 16px;
  
  a {
	  display: flex;
	  align-items: center;
  }
  
  @media screen and ${screenSize.md} {
	margin-top: -18px;
  }
`;

const Title = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.4px;
  
  margin-bottom: 24px;
`;

export const Footer = () => {
	const { t } = useTranslation('footer');

	return (
		<Wrapper>
			<Row>
				<Column style={{ flex: 1 }}>
						<LogoWrapper>
					<Link to={'/'}>
							<LogoIcon style={{ paddingTop: '10px' }}/>
							<div style={{ color: '#ffffff' }}>SmartSwap</div>
					</Link>
						</LogoWrapper>
				</Column>
				{footerItems.map((column, index) => (
					<Column key={index}>
						<Title>{t(column.title)}</Title>
						{column.items.map((item, index) => (
							<FooterLink key={index} href={item.href}>
								{t(item.title)}
							</FooterLink>
						))}
					</Column>
				))}
			</Row>

			<Row>
				<Column style={{ alignItems: 'center' }}>
					<a href={'https://kik.ee'}>
						<img style={{maxHeight: '64px'}} src={'/images/KIK_Toetab_valge.png'} alt={'Keskkonnainvesteeringute keskus'}/>
					</a>
				</Column>
			</Row>
		</Wrapper>
	);
};
