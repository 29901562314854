import React, { useContext } from 'react';
import { MessageCard } from './MessageCard';
import { VerifyEmailDialog } from './VerifyEmailDialog';
import { RecommendFriendDialog } from './RecommendFriendDialog';
import { MemberContext } from '@/contexts/MemberContext';
import { DialogContext } from '@/contexts/DialogContext';
import { useNavigate } from 'react-router-dom';

export const FriendInviteCard: React.FC = () => {
	const { flags, email } = useContext(MemberContext);
	const { showDialog, popDialog } = useContext(DialogContext);
	const navigate = useNavigate();

	function showEmailDialog() {
		if (!email) {
			navigate('/login');
		} else if (!flags?.isEmailVerified) {
			showDialog(<VerifyEmailDialog email={email} />);
		} else {
			showDialog(<RecommendFriendDialog onSuccess={popDialog} />);
		}
	}

	return (
		<MessageCard key={'recommendFriend'} onClick={showEmailDialog}>
			Kutsu sõbrad kasutajaks ja teeni punkte!
		</MessageCard>
	);
};
