import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface NotificationIconProps {
	notifications: boolean;
}

const Wrapper = styled.div`
	display: flex;
	position: relative;
`;

const Svg = styled.svg`
	position: absolute;
	top: -5px;
	right: -5px;
`;

const CircleSvg = () => (
	<Svg viewBox={'0 0 10 10'} width={10} height={10}>
		<circle cx={4} cy={4} r={4} fill={'#FF3E1D'} strokeWidth={2} stroke={'#ffffff'} />
	</Svg>
);

export const NotificationIcon: React.FC<PropsWithChildren<NotificationIconProps>> = ({ children, notifications }) => {
	return (
		<Wrapper>
			{children}
			{notifications && <CircleSvg />}
		</Wrapper>
	);
};
