import React, { PropsWithChildren, useContext } from 'react';
import { Dialog } from '@/components/Dialog';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/Button';
import { DialogContext } from '@/contexts/DialogContext';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';



export const TradeFailedDialog: React.FC<PropsWithChildren> = ({children}) => {
	const dialog = useContext(DialogContext);
	const { t } = useTranslation(['checkout']);
	// const navigate = useNavigate();
	// const queryClient = useQueryClient();

	async function onClose() {
		dialog.closeAll();
		// await queryClient.invalidateQueries('offers');
		// navigate('/offers');
	}

	return (
		<Dialog title={t('failed.title')}>
			{children}
			<Button $width={'full'} onClick={onClose}>
				{t('common:dismiss')}
			</Button>
		</Dialog>
	);
};
