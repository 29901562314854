import { useState } from 'react';
import { Country, LookupApi } from '@smartswap/client-api';
import useAsyncEffect from 'use-async-effect';
import { config } from '@/configuration';

const lookupApi = new LookupApi(config);

export function useCountries() {
	const [countries, setCountries] = useState<Country[]>([]);

	async function update() {
		const lookupResponse = await lookupApi.lookupCountriesGet();
		setCountries(lookupResponse.countries || []);
	}

	useAsyncEffect(update, []);

	return { countries };
}
