import { MarketApi, MyActivityGetRequest, MyActivityGetStateEnum } from '@smartswap/client-api';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { GiveawayItem } from '@/components/GiveawayItems/GiveawayItem';
import React from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { config } from '@/configuration';
import InfiniteScroll from 'react-infinite-scroller';
import { capitalize } from 'lodash';
import { useSearchParams } from 'react-router-dom';

const ListGroupa = styled.div``;

const groups: MyActivityGetStateEnum[] = [
	MyActivityGetStateEnum.Draft,
	MyActivityGetStateEnum.Reserved,
	MyActivityGetStateEnum.Published,
	MyActivityGetStateEnum.Traded,
];

export const GiveawayItemsList = () => {
	const [searchParams] = useSearchParams();
	const filter = searchParams.get('filter');

	if (filter && filter !== 'all') {
		return <GiveawayCategoryGroup key={filter} category={filter as MyActivityGetStateEnum} />
	}

	return groups.map((state) => {
		return <GiveawayCategoryGroup key={state} category={state} />;
	});
};

const marketApi = new MarketApi(config);

interface GiveawayCategoryListProps {
	category: MyActivityGetStateEnum;
}

const GiveawayCategoryGroup = ({ category }: GiveawayCategoryListProps) => {
	const { t } = useTranslation('my-items');
	const queryClient = useQueryClient();
	const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(['my-items', category], {
		queryFn: ({ pageParam: cursor }) => {
			let requestParam: MyActivityGetRequest = {
				state: category,
			};

			if (cursor) {
				requestParam = {
					...requestParam,
					cursor,
				};
			}
			return marketApi.myActivityGet(requestParam);
		},
		getNextPageParam: (lastResponse) => lastResponse?.paging?.nextCursor,
	});

	const handleItemChange = () => {
		queryClient.invalidateQueries('my-items');
	};

	if (!data || data.pages[0]?.offers?.length === 0) return null;

	return (
		<ListGroupa>
			<h3>{capitalize(t(`offerStates.${category}`))}</h3>
			<InfiniteScroll loadMore={() => fetchNextPage()} hasMore={hasNextPage}>
				{data?.pages?.map((page) => {
					return page.offers?.map((offer) => (
						<GiveawayItem key={offer.id} offer={offer} onItemChange={handleItemChange} />
					));
				})}
			</InfiniteScroll>
		</ListGroupa>
	);
};
