import React from 'react';
import styled from 'styled-components';

export const defaultColors = ['#A62A21', '#7e3794', '#0B51C1', '#3A6024', '#A81563', '#B3003C'];

// https://en.wikipedia.org/wiki/Linear_congruential_generator
function _stringAsciiPRNG(value: string, m: number): number {
	// Xn+1 = (a * Xn + c) % m
	// 0 < a < m
	// 0 <= c < m
	// 0 <= X0 < m

	const charCodes = Array.from(value).map((letter) => letter.charCodeAt(0));
	const len = charCodes.length;

	const a = (len % (m - 1)) + 1;
	const c = charCodes.reduce((current, next) => current + next) % m;

	let random = charCodes[0] % m;
	for (let i = 0; i < len; i++) random = (a * random + c) % m;

	return random;
}

export function getRandomColor(value: string, colors = defaultColors) {
	// if no value is passed, always return transparent color otherwise
	// a rerender would show a new color which would will
	// give strange effects when an interface is loading
	// and gets rerendered a few consequent times
	if (!value) return 'transparent';

	// value based random color index
	// the reason we don't just use a random number is to make sure that
	// a certain value will always get the same color assigned given
	// a fixed set of colors
	const colorIndex = _stringAsciiPRNG(value, colors.length);
	return colors[colorIndex];
}

function getInitials(name: string) {
	if (!name) return '';

	const initials = name
		.split(' ')
		.map((word) => word[0])
		.join('')
		.toUpperCase();

	return initials.charAt(0) + initials.slice(-1);
}

const AvatarWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 1000px;
	width: 32px;
	height: 32px;
	font-size: 12px;
	font-weight: 600;
	font-family: Helvetica, Arial, sans-serif;
`;

interface AvatarProps {
	name: string;
}

export function Avatar({ name }: AvatarProps) {
	const initials = getInitials(name);
	return (
		<AvatarWrapper style={{ background: getRandomColor(name), color: 'var(--color-gray-2)' }}>
			{initials}
		</AvatarWrapper>
	);
}
