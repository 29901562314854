import React, { CSSProperties } from 'react';
import { Offer } from '@smartswap/client-api';
import { CategoryPlaceholder } from '@/components/Categories/CategoryPlaceholder';
import styled from 'styled-components';
import { screenSize } from '@/ScreenSize';

const ItemImage = styled.img`
	max-height: 400px;
	object-fit: cover;
	height: auto;

	@media screen and ${screenSize.mobile} {
		max-height: 320px;
	}
`;

interface OfferFeaturedImageProps {
	className?: string;
	style?: CSSProperties;
	offer: Offer;
}

export const OfferFeaturedImage = ({ offer, className, style }: OfferFeaturedImageProps) => {
	const hasImage = !!offer?.media?.length && offer.media.length > 0;
	return hasImage ? (
		<ItemImage className={className} style={style} src={offer?.media?.[0].src} />
	) : (
		<CategoryPlaceholder className={className} style={style} slug={offer.details.category.slug} />
	);
};

export const OfferThumbnail = styled(OfferFeaturedImage)`
  && {
	height: 50px;
  	width: 50px;
 	object-fit: cover;
 	border-radius: 4px;
	transform: translate3d(0px, 0px, 0.1px);

    & img {
      width: initial;
    }
  }
`;
