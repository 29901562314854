import { AuthLoginResponse, Configuration } from '@smartswap/client-api';

const USER_KEY = 'user';

export const setUserInStorage = (auth: AuthLoginResponse | null) => {
	if (!auth) {
		localStorage.removeItem('user');
	} else {
		localStorage.setItem('user', JSON.stringify(auth));
	}
};

export const getUserFromStorage = (): AuthLoginResponse | null => {
	const userString = localStorage.getItem(USER_KEY);

	if (userString) {
		const data = JSON.parse(userString);
		const expiresAt = Date.parse(data.expiresAt);

		if (!data.memberId || data.memberId.length < 4) {
			// force anyone with a numerical id to log in again
			return null; // expire old tokens
		} else if (Date.now() > expiresAt) {
			return null;
		} else {
			return data;
		}
	}

	return null;
};
const getAccessToken = (): string => {
	const storageUser = getUserFromStorage();

	if (storageUser) {
		const tokenType = storageUser.tokenType.charAt(0).toUpperCase() + storageUser.tokenType.slice(1);
		return `${tokenType} ${storageUser.accessToken}`;
	}

	return '';
};

export const config = new Configuration({
	// accessToken: getAccessToken, //Use with OpenAPI v3
	apiKey: getAccessToken, //Use with Swagger 2.0
	basePath: process.env.REACT_APP_API_PATH,
	headers: {
		Accept: 'content/json',
	},
});
