import React, { createContext, PropsWithChildren, Suspense, useContext, useEffect, useState } from 'react';

interface DialogContextProps {
	components: JSX.Element[];
	showDialog: (component: JSX.Element) => void;
	popDialog: () => void;
	closeAll: () => void;
}

export const DialogContext = createContext<DialogContextProps>({} as DialogContextProps);

export const DialogContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [components, setComponents] = useState<JSX.Element[]>([]);

	useEffect(() => {
		document.body.style.overflow = components.length > 0 ? 'hidden' : 'visible'; // TODO: take into account previous value
	}, [components]);

	const showDialog = (component: JSX.Element) => {
		setComponents((prevState) => [...prevState, { ...component, key: `${components.length}` }]);
	};

	const popDialog = () => {
		setComponents((prevState) => {
			const copy = [...prevState];
			copy.pop();
			return copy;
		});
	};

	const closeAll = () => {
		setComponents([]);
	};

	return (
		<DialogContext.Provider value={{ components, showDialog, popDialog, closeAll }}>
			{children}
		</DialogContext.Provider>
	);
};
export const useDialog = () => useContext(DialogContext);

export const DialogRoot: React.FC = () => {
	const { components } = useContext(DialogContext);

	return <Suspense fallback={null}>{components}</Suspense>;
};
