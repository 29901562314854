import React, { CSSProperties, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { screenSize } from '@/ScreenSize';

const ActiveIndicator = styled.div`
	width: 4px;
	height: auto;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
`;

const Content = styled.div`
	margin: 0 8px;
	padding: 8px 12px;
	border-radius: 5px;
	flex: 1;
	display: flex;
	flex-flow: row;
	align-items: center;

	@media screen and ${screenSize.mobile} {
		margin: 0;
	}
`;

const Wrapper = styled.div`
	font-family: 'InterUI';
	font-weight: normal;
	display: flex;
	flex-flow: row;
	align-items: stretch;
	min-height: 50px;

	a.active &, &:hover{
		${ActiveIndicator} {
			background: rgba(105, 108, 255, 1);
		}

		${Content} {
			background-color: var(--color-primary-pastel);
		}
	}
`;

interface SidebarItemProps {
	className?: string;
	style?: CSSProperties;
}

export const SidebarItem: React.FC<PropsWithChildren<SidebarItemProps>> = ({ children, className }) => {
	return (
		<Wrapper className={className}>
			<Content>{children}</Content>
		</Wrapper>
	);
};
