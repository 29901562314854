import React, { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { AuthLoginResponse } from '@smartswap/client-api';
import { getUserFromStorage, setUserInStorage } from '@/configuration';
import { useNavigate } from 'react-router-dom';

interface AuthContextProps {
	isLoggedIn: () => boolean;
	getAccessToken: () => string | null;
	getLoginResponse: () => AuthLoginResponse | null;
	login: (response: AuthLoginResponse) => void;
	logout: () => void;
}

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthContextProvider: React.FC<PropsWithChildren> = (props) => {
	const [loginResponse, setLoginResponse] = useState<AuthLoginResponse | null>(null);
	const [tick, setTick] = useState<number | null>(null);
	const timerId = useRef<number | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (!loginResponse) {
			timerId.current = null;
			return;
		}

		// work around setTimeout() overflow
		const oneDay = 1000 * 60 * 60 * 24;
		const delay = Math.min(oneDay, new Date(loginResponse.expiresAt).getTime() - Date.now());

		if (delay <= 0) {
			console.log('user session has expired');
			setLoginResponse(null);
			return;
		}

		timerId.current = window.setTimeout(() => setTick(Date.now()), delay);

		return () => {
			window.clearTimeout(timerId.current || undefined);
			timerId.current = null;
		};
	}, [loginResponse, tick]);

	useEffect(() => {
		setLoginResponse(getUserFromStorage());
	}, []);

	function isLoggedIn(): boolean {
		return !!getAccessToken();
	}

	function getAccessToken(): string | null {
		return getLoginResponse()?.accessToken || null;
	}

	function getLoginResponse(): AuthLoginResponse | null {
		if (!loginResponse) {
			return null;
		}

		if (new Date() >= loginResponse.expiresAt) {
			setLoginResponse(null);
			return null;
		}

		return loginResponse;
	}

	function login(auth: AuthLoginResponse) {
		setUserInStorage(auth);
		setLoginResponse(auth);
	}

	function logout() {
		setLoginResponse(null);
		setUserInStorage(null);
		navigate('/');
	}

	return (
		<AuthContext.Provider
			value={{
				isLoggedIn,
				getAccessToken,
				getLoginResponse,
				login,
				logout,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => useContext(AuthContext);
