import React, { useContext } from 'react';
import { Dialog } from './Dialog';
import styled from 'styled-components';
import './TradeCreatedDialog.css';
import { useNavigate } from 'react-router-dom';
import { GreenWorldIcon } from '@/Icons/marketing';
import { Button } from './Button';
import { DialogContext } from '@/contexts/DialogContext';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

const DialogComponent = styled(Dialog)`
	justify-content: center;
	align-items: center;
`;

const Wrapper = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	font-face: 'InterUI', sans-serif;
	font-weight: normal;
	line-height: 1.57;
	color: #72809d;
`;

const Title = styled.p`
	margin-top: 32px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 1.5;

	letter-spacing: -0.4px;
	color: #35425b;
`;

export const TradeCreatedDialog: React.FC = () => {
	const dialog = useContext(DialogContext);
	const { t } = useTranslation('checkout');
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	async function onClose() {
		dialog.closeAll();
		await queryClient.invalidateQueries('offers');
		navigate('/offers');
	}

	return (
		<DialogComponent onClick={onClose}>
			<Wrapper>
				<GreenWorldIcon />
				<Title>{t('confirmation.title')}</Title>
				<p dangerouslySetInnerHTML={{ __html: t('confirmation.content', { postProcess: 'md' }) }} />
				<Button style={{ marginBottom: '24px' }} $width={'full'} onClick={onClose}>
					{t('confirmation.dismiss')}
				</Button>
			</Wrapper>
		</DialogComponent>
	);
};
