import styled, { keyframes } from 'styled-components';
import { Card } from '@/components/Card/Card';
import React, { CSSProperties, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { Offer, OfferStateEnum } from '@smartswap/client-api';
import Masonry from 'react-masonry-css';
import { useTranslation } from 'react-i18next';
import { OfferFeaturedImage } from '@/components/Offer/OfferFeaturedImage';
import { UpvoteButtonSmall } from '@/components/Upvote/UpvoteButton';

interface ItemCardProps {
	readonly $highlight: boolean;
}

//<editor-fold desc="Styles">
const fadein = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ItemCard = styled(Card)<ItemCardProps>`
	width: initial;
	font-size: 16px;
	font-weight: 500;
	font-family: 'InterUI';
	position: relative;
	max-width: 320px;
	border: ${(props) => (props.$highlight ? '1px solid #89abd2' : 'none')};
  	overflow: hidden;

	animation: ${fadein} 0.5s;

	& > * {
		padding: 0;
	}
`;

const CardContent = styled.div`
	padding: 12px 16px 16px;
`;

const Ribbon = styled.div`
	font-family: Poppins;
	position: absolute;
	top: 0;
	left: 0;
	transform: rotate(-45deg) translate(-50%, 40px);
	transform-origin: top left;
	line-height: 1.84;

	font-weight: bold;
	font-size: 12px;
	background: var(--color-brand);
	width: 200px;
	text-align: center;
	color: var(--color-default);
`;

const ImageContainer = styled.div`
	position: relative;
 	display: flex;
`;
//</editor-fold>

type CardProps = {
	className?: string;
	style?: CSSProperties;
	offer: Offer;
	highlight?: boolean;
	viewOnly?: boolean;
};

const breakpointColumnsObj = {
	default: 4,
	1100: 3,
	700: 2,
};

export const OfferCard: React.FC<CardProps> = (props) => {
	const offer = props.offer;
	const { t } = useTranslation('my-items');

	if (!offer.details) {
		return null;
	}

	// Archived or swapped items are view only - can see card but not details
	const readOnly = props.viewOnly || offer.state === OfferStateEnum.Traded;

	const cardContent = (
		<React.Fragment>
			<ImageContainer>
				<OfferFeaturedImage
					style={{ maxHeight: '400px', minHeight: '200px', height: 'auto', width: '100%' }}
					offer={offer}
				/>
				<UpvoteButtonSmall offer={offer} />
			</ImageContainer>
			<CardContent>{offer.details.title}</CardContent>
		</React.Fragment>
	);

	return (
		<ItemCard className={props.className} style={props.style} $highlight={props.highlight || false}>
			{readOnly ? <div>{cardContent}</div> : <NavLink to={`/item/${offer.id}`}>{cardContent}</NavLink>}
			{offer.state === OfferStateEnum.Traded && <Ribbon>{t('common:swapped').toUpperCase()}</Ribbon>}
		</ItemCard>
	);
};

type GridProps = {
	offers: Offer[];
	viewOnly?: boolean;
	highlight?: string | null;
};

export const OffersGrid: React.FC<PropsWithChildren<GridProps>> = ({ offers, children, highlight, viewOnly }) => {
	return (
		<Masonry breakpointCols={breakpointColumnsObj} className={'masonry'} columnClassName={'masonry-column'}>
			{children}
			{offers.map((offer) => (
				<OfferCard
					key={offer.id}
					offer={offer}
					highlight={!!highlight && highlight === offer.id}
					viewOnly={viewOnly}
				/>
			))}
		</Masonry>
	);
};
