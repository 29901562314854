import React, { useContext } from 'react';
import { NotificationIcon } from './Reminders/NotificationIcon';
import { MessageSquare } from 'react-feather';
import { iconDefaultProps } from '@/Icons/IconDefaultProps';
import { Link } from 'react-router-dom';
import { RemindersContext } from './RemindersContext';

export const ChatLink: React.FC = () => {
	const { nonAckedChats } = useContext(RemindersContext);

	return (
		<Link to={'/messages'}>
			<NotificationIcon notifications={nonAckedChats > 0}>
				<MessageSquare {...iconDefaultProps} />
			</NotificationIcon>
		</Link>
	);
};
