import { useQuery } from './useQuery';
import { useEffect, useMemo } from 'react';

export function useUrlLocaleSwitcher(callback: (locale: string) => void) {
	const query = useQuery();
	const lang = useMemo(() => query.get('lang'), [query]);

	useEffect(() => {
		if (lang) {
			callback(lang);
		}
	}, [callback, lang]);
}
